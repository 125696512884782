import React from 'react';
// import logo from './logo.svg';
// @ts-ignore 

// import {RequireUser} from './components'
import { Route, Routes } from 'react-router-dom';

import './App.css';
// import Unauthorized from './pages/Unauthorized';
import HomeLayout from './pages/HomeLayout';

import VerifyEmail from './components/VerifyEmail';

import './i18n';


import ForgotPasswordPage from './pages/ForgotPasswordPage';

import NewLoginPage from './pages/New/NewLoginPage';
import NewRegisterPage from './pages/New/NewRegisterPage';
import FAQPage from './pages/New/FAQPage';
import PaymentPage from './pages/New/PaymentPage';
import HomePage from './pages/New/HomePage';
import ProfilePage from './pages/New/ProfilePage';
import NewForgotPasswordPage from './pages/New/NewForgotPasswordPage';
import NotFoundPage from './pages/New/NotFoundPage';

function App() {

  //check authorization

  return (
    <Routes>
      <Route path='/' element={<HomeLayout/>} >
        <Route index element={<HomePage />}/>
        <Route path='login' element={<NewLoginPage />} />
        <Route path='register' element={<NewRegisterPage />} />
        <Route path='forgotpassword' element={<NewForgotPasswordPage />} />
        {/* <Route path='pricing' element={<PricingPage />} /> */}
        <Route path='payment' element={<PaymentPage />} />
        {/* <Route path='profile' element={<ProfilePage />} /> */}
        <Route path='faq' element={<FAQPage />} />
        {/* <Route path='forgotpassword' element={<ForgotPasswordPage />} /> */}
        <Route path='api/auth/verifyemail'>
          <Route path=':verificationCode' element={<VerifyEmail/>} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
