import { createStyles, Overlay, Container, Title, Button, Text, Flex } from '@mantine/core';
import { NewLoginForm } from '../../components/New/Forms/NewLoginForm';
import { useNavigate,useLocation } from 'react-router-dom';
import { NewForgotPasswordForm } from '../../components/New/Forms/NewForgotPasswordForm';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  
  container: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing.xl * 2,
    paddingTop: theme.spacing.xl * 2,
    paddingRight: theme.spacing.xl * 2,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      height: 500,
      paddingBottom: theme.spacing.xl * 3,
    },
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  title: {
    color: theme.white,
    fontSize: 60,
    fontWeight: 900,
    lineHeight: 1.1,
    maxWidth: 800,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 40,
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 800,

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: theme.spacing.xl * 1.5,
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

const NewForgotPasswordPage = () => {
  const { classes } = useStyles();
  let navigate = useNavigate();
  let location = useLocation();
  const { t, i18n } = useTranslation();
  
  return (
    <div className={'bg-cover bg-center flex flex-row items-center w-[100vw] h-[90vh] top-0' }>
      {/* <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      /> */}
      <div className='flex flex-col sm:flex-row items-center justify-between w-[100vw] sm:-mt-4 sm:px-12 xl:px-16 2xl:px-24'>
        <div className={classes.container}>
          <Title className={classes.title}>{t("components.secondHero.header.title")} </Title>
          <Text className={classes.description} size="xl" mt="xl">
          {t("components.secondHero.header.description")}
          </Text>

          <a href='/'>
            <Button variant="filled" color={'teal'} size="xl" radius="xl" className={classes.control}>
            {t("components.secondHero.header.button.btnSubmit")}
            </Button>
          </a>
        </div>
        <NewForgotPasswordForm />
      </div>
    </div>
  );
}

export default NewForgotPasswordPage