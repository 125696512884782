// import { BackgroundImage, Overlay } from '@mantine/core'
import React, { useEffect } from 'react'
// import BackgroundDots from '../../assets/beyazgorsel.png'
import { WelcomingHero } from '../../components/New/Sections/WelcomingHero'
import NewPricingSection from '../../components/New/Sections/NewPricingSection'
import NewFeaturesSection from '../../components/New/Sections/NewFeaturesSection'
import NewStatisticsSection from '../../components/New/Sections/NewStatisticsSection'
import NewContactSection from '../../components/New/Sections/NewContactSection'
import NewGallerySection from '../../components/New/Sections/NewGallerySection'
import { useGetPricesDataMutation } from '../../services/pricesApi'
import NewHeroSection from '../../components/New/Sections/NewHeroSection'
// useMemo(() => first, [second])
const HomePage = () => {

  const [ getPrices, response] = useGetPricesDataMutation()
  
  useEffect(() => {
    getPrices()
  }, [])
  
  return (
    <div className={'flex flex-col items-center min-w-screen w-screen overflow-x-hidden sm:scroll-smooth  sm:snap-always relative min-h-screen  lg:px-24 sm:snap-mandatory sm:snap-y' }>
    {/* <BackgroundImage src={BackgroundDots} radius={'xs'}> */}
      {/* <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      /> */}
      {/* <WelcomingHero /> */}
      <NewHeroSection />
      <NewFeaturesSection />
      <NewStatisticsSection />
      <NewGallerySection/>
      <NewPricingSection />
      <NewContactSection />
      {/* <PricingHero /> */}
    {/* </BackgroundImage> */}
    </div>
  )
}

export default HomePage