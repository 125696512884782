/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useTranslation } from 'react-i18next';

const NewFeaturesSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="text-gray-600 body-font z-50 min-h-screen h-full sm:snap-center ">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-6xl text-[42px] font-extrabold  title-font text-[#009999] mb-4 ">{t("pages.home.features.header.title")}</h1>
          <p className="text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto px-4 sm:px-0 text-gray-100">{t("pages.home.features.header.description")}</p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-[#009999] inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-[#009999] mb-5 flex-shrink-0">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-100 text-xl title-font font-semibold mb-6">{t("pages.home.features.feature1.title")}</h2>
              <p className="leading-relaxed text-lg text-white">{t("pages.home.features.feature1.description")}​</p>
              {/* <a className="mt-3 text-[#009999] inline-flex items-center">Learn More
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a> */}
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-[#009999] mb-5 flex-shrink-0">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                <circle cx="6" cy="6" r="3"></circle>
                <circle cx="6" cy="18" r="3"></circle>
                <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
              </svg>
            </div>
            
            <div className="flex-grow">
              <h2 className="text-gray-100 text-xl title-font font-semibold mb-6">{t("pages.home.features.feature2.title")}</h2>
              <p className="leading-relaxed text-lg text-white">{t("pages.home.features.feature2.description")}</p>
              {/* <a className="mt-3 text-[#009999] inline-flex items-center">Learn More
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a> */}
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-indigo-100 text-[#009999] mb-5 flex-shrink-0">
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-100 text-xl title-font font-semibold mb-6">{t("pages.home.features.feature3.title")}</h2>
              <p className="leading-relaxed text-lg text-white">{t("pages.home.features.feature3.description")}</p>
              {/* <a className="mt-3 text-[#009999] inline-flex items-center">Learn More
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a> */}
            </div>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default NewFeaturesSection