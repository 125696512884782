import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Container,
  Button,
  createStyles,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import *  as Yup from 'yup'    
import { useForgotPasswordMutation, useRegisterUserMutation, useUpdatePasswordMutation } from '../../../services/authApi';
// import NotificationHandler from '../../Functional/NotificationHandler';
import { loaderIsOff, loaderIsOn } from '../../../slices/modalSlice';
import { useAppDispatch } from '../../../app/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({

  label: {
    color:'white',
  },
  button:{
    borderColor: '#009999',
    backgroundColor:'#009999',
    '&:hover': {
      boxShadow: "1px 1px 2px 2px #009999",
      backgroundColor:'#009999',
      border:'none'
    },
  },
  input: {
    '&:focus': {
      borderColor: '#009999'
    },
    '&:hover': {
      boxShadow: "1px 1px 2px 2px #009999",
      borderStyle:'none',
      border: 'none'
    },
  },
}));

interface formValues extends Yup.InferType<typeof validationSchema>{
  email: string;
}
interface formValues2 extends Yup.InferType<typeof validationSchema2>{
  email: string;
  passwordUpdateCode: string;
  password: string;
  passwordConfirm: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email format is wrong').required('Please enter email.')
})

const validationSchema2 = Yup.object({
  email: Yup.string().email('Email format is wrong.').required('Please enter email.'),
  passwordUpdateCode: Yup.string().min(2).required("Please enter recovery code."),
  password: Yup.string().min(8).required('Password must be at least 8 characters long'),
  passwordConfirm: Yup
      .string()
      .min(8)
      .required("Re-enter password!")
      .oneOf([Yup.ref("password"), null], "Passwords do not match!")
})


export function NewForgotPasswordForm() {

  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const [sendRecoveryCode, response] = useForgotPasswordMutation()
  const [sendNewPassword, response2] = useUpdatePasswordMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  
  const successNotification = (title:string,message:string) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.green[6],
          borderColor: theme.colors.green[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.green[7] },
        },
      }),
    })
  }

  const errorNotification = (title:string,message:any) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[6],
          borderColor: theme.colors.red[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.red[7] },
        },
      }),
    })
  }

  function navigateToLogin(){
    // event.preventDefault();
    console.log("To home")
    navigate("/login", { replace: true });
  } 

  return (
    <>
      {
      isFormSubmitted  === false ?
        <Formik 
          enableReinitialize
          initialValues ={{
            email: '',
          }}
          validationSchema={
            Yup.object({
              email: Yup.string().email(t("components.forms.register.validation.input2format")).required(t("components.forms.register.validation.input2")),
            })
          }
          onSubmit={async (
            values: formValues,
            { setSubmitting,resetForm }: FormikHelpers<formValues>
            ) => {
              //sends form values to the server then processes response data on handleSubmit function
              try {
                dispatch(loaderIsOn())
                const payload = await sendRecoveryCode(values).unwrap();
                resetForm()
                setFormSubmitted(true)
                setTimeout(() => {
                  successNotification(t(`components.successes.title`),t(`components.successes.forgot.text1`))
                  dispatch(loaderIsOff())
                }, 2000);
                console.log('fulfilled ', payload);
              } catch (error:any) {
                dispatch(loaderIsOff())
                errorNotification(t(`components.errors.title`),t(`components.errors.${error.data.errorCode.toString()}`))
                console.error('rejected ', error);
              }
            } 
          }
        >
          {({
              isSubmitting, 
              submitForm, 
              isValid, 
              dirty, 
              errors, 
              values
            }: any) => (
          <Form style={{zIndex: 1000}}>
            <div className='z-0 2xl:mr-52 sm:mr-10 lg:mr-18 md:mr-15'>
              <Container  size={420} px={12} pt={20} pb={25} my={0} sx={{backgroundColor:'transparent' , display:'flex', flexDirection:'column',borderRadius:'25px', borderWidth:'0px', minWidth:'340px', borderColor:'white' , minHeight: 'auto'}}>
                <Title
                  align="center"
                  sx={(theme) => ({ fontWeight: 600, color:'white', px:2 })}
                >
                  {t("components.forms.forgot.header")} 
                </Title>

                <Paper py={20} px={20} mt={30} radius="md" sx={{backgroundColor:'transparent', height:'auto'}}>
                  
                  <Field  name="email">
                    {({field, form}:any)=>(
                      <TextInput 
                        {...field}
                        classNames={classes}
                        name='email' 
                        type={'text'} 
                        error={form.touched.email && form.errors.email ? <ErrorMessage name={'email'} /> : null }
                        label={t("components.forms.forgot.inputEmail")} 
                        placeholder={t("components.forms.forgot.placeholder1")} 
                        required 
                        mt="md" 
                      />
                    )}
                  </Field>
                  
                  <Button className={classes.button} 
                    variant='filled'  
                    fullWidth mt="xl"
                    type='submit'
                    loading={isSubmitting}
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    {t("components.forms.forgot.button.btnSubmit")} 
                  </Button>
                </Paper>
              </Container>
            </div>
          </Form>
          )}
        </Formik>
        :
        <Formik 
          enableReinitialize
          initialValues ={{
            passwordUpdateCode:'',
            email: '' ,
            password:'',
            passwordConfirm:'',
          }}
          validationSchema={
            Yup.object({
              passwordUpdateCode: Yup.string().required(t("components.forms.register.validation.input1")),
              email: Yup.string().email(t("components.forms.register.validation.input2format")).required(t("components.forms.register.validation.input2")),
              password: Yup.string().min(8,t("components.forms.register.validation.input3min")).max(25,t("components.forms.register.validation.input3max")).required(t("components.forms.register.validation.input3")),
              passwordConfirm: Yup
                  .string()
                  .required(t("components.forms.register.validation.input4"))
                  .oneOf([Yup.ref("password"), null], t("components.forms.register.validation.input5")),
            })
          }
          onSubmit={async (
            values: formValues2,
            { setSubmitting, resetForm }: FormikHelpers<formValues2>
            ) => {
              //sends form values to the server then processes response data on handleSubmit function
              try {
                dispatch(loaderIsOn())
                const payload = await sendNewPassword(values).unwrap();
                setTimeout(() => {
                  successNotification(t(`components.successes.title`),t(`components.successes.forgot.text2`))
                  resetForm()
                  setFormSubmitted(true)
                  navigateToLogin()
                  dispatch(loaderIsOff())
                }, 2000);
                console.log('fulfilled ', payload);
              } catch (error:any) {
                dispatch(loaderIsOff())
                errorNotification(t(`components.errors.title`),t(`components.errors.${error.code}`))
                console.error('rejected ', error);
              }
            } 
          }
        >
          {({
              isSubmitting, 
              submitForm, 
              isValid, 
              dirty, 
              errors, 
              values
            }: any) => (
          <Form style={{zIndex: 1000}}>
            <div className='-z-0  2xl:mr-38 sm:mr-8 lg:mr-16 md:mr-12'>
              <Container  size={420} px={12} pt={20} pb={25} my={0} sx={{backgroundColor:'transparent' , display:'flex', flexDirection:'column',borderRadius:'25px', borderWidth:'0px', minWidth:'340px', borderColor:'white' , minHeight: 'auto'}}>
                <Title
                  align="center"
                  sx={(theme) => ({ fontWeight: 600, color:'white', px:2 })}
                >
                  {t("components.forms.forgot.header2")}
                </Title>

                <Paper py={20} px={20} mt={30} radius="md" sx={{backgroundColor:'transparent', height:'auto'}}>
                  <Field  name="passwordUpdateCode">
                    {({field, form}:any)=>(
                      <TextInput
                        {...field}
                        error={form.touched.passwordUpdateCode && form.errors.passwordUpdateCode ? <ErrorMessage name={'passwordUpdateCode'} /> : null }
                        classNames={classes}
                        label={t("components.forms.forgot.text1")}
                        placeholder={t("components.forms.forgot.placeholder2")}
                        required 
                        mt="md" 
                      />
                    )}
                  </Field>
                  <Field  name="email">
                    {({field, form}:any)=>(
                      <TextInput 
                        {...field}
                        classNames={classes}
                        name='email'
                        error={form.touched.email && form.errors.email ? <ErrorMessage name={'email'} /> : null }
                        label={t("components.forms.forgot.inputEmail")}
                        placeholder="mail@ceremeet.com" 
                        required 
                        mt="md" 
                      />
                    )}
                  </Field>
                  <Field  name="password">
                  {({field, form}:any)=>(
                    <PasswordInput 
                      {...field}
                      classNames={classes}
                      name='password' 
                      error={form.touched.password && form.errors.password ? <ErrorMessage name={'password'} /> : null }
                      label={t("components.forms.forgot.inputPwd")}
                      placeholder={t("components.forms.register.placeholder1")}
                      mt="md" 
                      required 
                    />
                  )}
                </Field>
                <Field  name="passwordConfirm">
                  {({field, form}:any)=>(
                    <PasswordInput 
                      {...field}
                      classNames={classes}
                      name='passwordConfirm' 
                      error={form.touched.passwordConfirm && form.errors.passwordConfirm ? <ErrorMessage name={'passwordConfirm'} /> : null }
                      label={t("components.forms.forgot.inputConPwd")}
                      placeholder={t("components.forms.register.placeholder2")}
                      mt="md" 
                      required 
                    />
                  )}
                </Field>
                  <Button className={classes.button} 
                  variant='filled' 
                  fullWidth 
                  mt="xl"
                  loading={isSubmitting}
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={submitForm}
                  >
                  {t("components.forms.forgot.button.btnSubmit")}
                  </Button>
                </Paper>
              </Container>
            </div>
          </Form>
          )}
        </Formik>
      }
    </>
  );
}