/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from 'react'
// import ContainedInputs from '../../components/New/ContainedInputs'
// import { TextInput } from '@mantine/core'
import PaymentTextInput from '../../components/New/FormComponents/PaymentTextInput'
// import { Textarea } from '@mantine/core';
import TextAreaInput from '../../components/New/FormComponents/TextAreaInput';
import { Button, Stepper, Text, Title } from '@mantine/core';
// import BasketContainer from '../../components/New/BasketContainer';
import *  as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { showNotification } from '@mantine/notifications';
import { useUpdateUserInfoMutation } from '../../services/userApi';
import { useCreateNewAddressMutation, useGetAddressInfoQuery, useGetAllAddressInfoQuery } from '../../services/addressApi';
import { useCreateSubscriptionMutation } from '../../services/paymentApi';
import { loaderIsOff, loaderIsOn } from '../../slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PriceSelectionCard from '../../components/New/Cards/PriceSelectionCard';
import { deleteSelectedSubType, setSelectedSubType } from '../../slices/pricesSlice';
import { PaymentAddressSelect } from '../../components/New/PaymentAddressSelect';
import BasketContainer from '../../components/New/BasketContainer';
import Confetti from '../../assets/confetti3.gif'
import { useNavigate } from 'react-router-dom';
import IyzicoLogo from '../../assets/iyzico_logo.png'
import { setSelectedAddress } from '../../slices/addressSlice';
import { useTranslation } from 'react-i18next';
import { IPriceItem } from '../../services/types';
// function classNames(...classes: string[]) {
//     return classes.filter(Boolean).join(' ')
// }

type ICurrencyCode = 'USD' | 'TRY'
// matches(/^([a-zA-Z]{1,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]{1,}\s?([a-zA-Z]{1,})?)/,'Please enter the firstname and lastname!')
const validationSchema = Yup.object({
  name: Yup.string().min(2).required('Please enter your name!'),
  // nickname: Yup.string().min(3).required('Please enter your username!'),
  identityNumber: Yup.string().notRequired().optional(),
  gsmNumber: Yup.string().min(3).matches(/^(\d{1,4})?(?!0+\s+,?$)\d{10}\s*,?$/, 'Type your number without + or any space').required('Please enter your GSM Number!'),
  country: Yup.string().min(3).required('Please enter your country!'),
  notTurkishCitizen: Yup.boolean().required('Please enter your country!'),
})
const validationSchema2 = Yup.object({
  name: Yup.string().min(3).max(20).required('Please enter address name!'),
  address: Yup.string().min(3).max(60).required('Please enter address text!'),
  city: Yup.string().min(3).max(20).required('Please enter your city!'),
  country: Yup.string().min(3).max(20).required('Please enter your country!'),
  zipCode: Yup.string().min(5).max(5).required('Please enter your Zip Code!'),
})
const validationSchema3 = Yup.object({
  cardHolderName: Yup.string().min(3).required('Please enter Cardholder name!'),
  cardNumber: Yup.string().required('Please enter card number!'),
  expireYear: Yup.string().min(2).max(2).required('Please enter your expire year!'),
  expireMonth: Yup.string().min(2).max(2).required('Please enter your expire month!'),
  cvc: Yup.string().min(3).max(3).required('Please enter your cvc!'),
  registerConsumerCard: Yup.boolean(),
  address: Yup.string().required('Please enter address!'),
  pricingPlanReferenceCode: Yup.string().required('Please enter your zipCode!'),
})

interface formValues1 extends Yup.InferType<typeof validationSchema>{
  name: string;
  // nickname: string;
  identityNumber: string ;
  gsmNumber: string;
  country: string;
  notTurkishCitizen: boolean;
}
interface formValues2 extends Yup.InferType<typeof validationSchema2>{
  id?: string;
  name: string;
  address: string;
  city: string;
  country: string;
  zipCode: string;
}
export interface formValues3 extends Yup.InferType<typeof validationSchema3>{
  cardHolderName: string;
  cardNumber: string;
  expireYear: string;
  expireMonth: string;
  cvc: string;
  registerConsumerCard: boolean;
  address: string;
  pricingPlanReferenceCode: string;
}


  
const PaymentPage = () => {
  // const [activeSectionNumber, setfirst] = useState(second)
  let navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [priceSelected, setPriceSelected] = useState(false);
  const [priceSelected2, setPriceSelected2] = useState(false);
  const [currencyCode, setCurrencyCode] = useState<ICurrencyCode>('TRY') 
  const { t, i18n } = useTranslation();
  // const  ProfileInfo = useAppSelector((state) => {
  //   if(state.userState?.data.user !== undefined ){
  //     console.log(state.userState?.data)
  //     return state.userState?.data.user
  // }})

  const {
    data: addresses,
    isFetching,
    isLoading: addressesLoading,
    isSuccess: addressesSuccess,
  } = useGetAllAddressInfoQuery('')

  const [createNewAddress, response] = useCreateNewAddressMutation()
  const [updateUserInfo, response2] = useUpdateUserInfoMutation()
  const [createSubscription, response3] = useCreateSubscriptionMutation()
  const dispatch = useAppDispatch()
  
  const  pricesList = useAppSelector((state) => {
    if(state.priceState?.prices_list !== undefined ){
      // console.log(state.priceState.prices_list)
      return state.priceState?.prices_list
  }})

  const  selectedItem = useAppSelector((state) => {
    if(state.priceState?.selected_sub_type !== undefined ){
      // console.log("Selected price")
      // console.log(state.priceState.selected_sub_type)
      return state.priceState?.selected_sub_type
  }})

  const  selectedAddress = useAppSelector((state) => {
    if(state.addressState?.selected_address !== undefined ){
      // console.log("Selected address 2")
      // console.log(state.addressState.selected_address)
      return state.addressState?.selected_address
  }})

  // const  UserAdresses = useAppSelector((state) => {
  //   if(state.userState?.data.user !== undefined ){
  //     // console.log(state.userState?.data)
  //     return state.userState?.data.user.addresses
  // }})
  // if(addressesSuccess){
  //   console.log(addresses?.data.address)
  // }
  
  useEffect(() => {
    console.log(addressesSuccess + "adres alımı başarılı")
  }, [addressesSuccess])
  

  useEffect(() => {
    console.log(active);
    // console.log("Use Effect Çalıştı")
    setSubscriptionAndToggleSelection()
  }, [active,priceSelected, priceSelected2, currencyCode])
  
  const nextStep = () => setActive((current) => (current < 5 ? current + 1 : current));

  const successNotification = (title:string,message:string) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.green[6],
          borderColor: theme.colors.green[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.green[7] },
        },
      }),
    })
  }

  //error notification
  const errorNotification = (title:string,message:any) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[6],
          borderColor: theme.colors.red[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.red[7] },
        },
      }),
    })
  }
  
  const pricingSelectionNext = () => {
    dispatch(loaderIsOn())
    setTimeout(() => {
      nextStep()
      dispatch(loaderIsOff())
    }, 2000);
  }

  const setSubscriptionMonthly = () => {
    setPriceSelected(prev=>!prev)
    setPriceSelected2(false)
  }

  const setSubscriptionAnnual = () => {
    setPriceSelected2(prev=>!prev)
    setPriceSelected(false)
  }

  const setSubscriptionAndToggleSelection = () => {
    //Aylık
    if(priceSelected === true ){
      if(currencyCode === 'TRY'){
        dispatch(setSelectedSubType(pricesList.find( 
          (item:any) => item.pricingPlanReferenceCode === 'a9313160-0629-4fee-928b-405e8c946dc2'
        )))
      }
      else if(currencyCode === 'USD'){
        dispatch(setSelectedSubType(pricesList.find( 
          (item:any) => item.pricingPlanReferenceCode === 'b88b27d1-f383-476f-9488-ed6d83f018f2'
        )))
      }
    }
    // Yıllık
    else if( priceSelected2 === true){
      if(currencyCode === 'TRY'){
        dispatch(setSelectedSubType(pricesList.find( 
          (item:any) => item.pricingPlanReferenceCode === '848336bc-b657-49ce-9171-3b22dbd7a711'
        )))
      }
      else if(currencyCode === 'USD')
      {
        dispatch(setSelectedSubType(pricesList.find( 
          (item:any) => item.pricingPlanReferenceCode === 'f2478067-5991-499c-bcef-c3de0e5f7326'
        )))
      }
    }
    else if( priceSelected === false && priceSelected2 === false ){
      dispatch(deleteSelectedSubType())
    }
  }

  function navigateToHome(){
    // event.preventDefault();
    console.log("To home")
    navigate("/", { replace: true });
  } 
  
  return (
    // page container
    <>
    
    <div className=' flex flex-row relative  w-[100vw] max-h-full min-h-[85vh] mb-28'>
      <div className='flex flex-row relative w-[100vw] justify-between sm:pr-10 xl:pr-12 2xl:pr-16 sm:pl-4 z-10 pt-16'>
        <div className="flex flex-col items-center relative w-screen sm:w-[60vw] px-6">
          {/* <button onClick={nextStep}>Skip</button> */}
          {/* Stepper Section */}
              <Stepper active={active} color={'teal'} sx={{width:'100%', color:'white', position:'relative', marginBottom:'3rem'}} breakpoint="sm" >


                {/* 1st Step Subscription Type Info */}
                <Stepper.Step  label={t("pages.payment.header.item1.text1")}  sx={{color:'white', alignItems:'center' }} description={t("pages.payment.header.item1.text2")}>
                  <div className='flex flex-col items-center w-full '>
                    <div className='flex flex-col relative' >
                    <div className="flex mx-auto border-2  border-[#009999] rounded overflow-hidden mt-6 mb-2">
                      <button onClick={()=>setCurrencyCode('USD')} className={`py-1 px-4 ${currencyCode === 'USD' ? 'bg-[#009999]' :'focus:outline-none'} text-white`}>USD</button>
                      <button onClick={()=>setCurrencyCode('TRY')} className={`py-1 px-4 ${currencyCode === 'TRY' ? 'bg-[#009999]' :'focus:outline-none'}`}>TRY</button>
                    </div>
                      {
                        pricesList?.map((item:any, index:any) => {
                          if(item.paymentInterval === 'Monthly' && item.currencyCode === currencyCode ) {
                            return <PriceSelectionCard selected={priceSelected} onClick={()=>setSubscriptionMonthly()} key={index} price={`${item.currencyCode === 'TRY' ? item.price +'₺' : '$'+ item.price}`} currencyCode={item.currencyCode} subscriptionType={t("pages.payment.form1.text1")} paymentInterval={`${item.currencyCode === 'TRY' ? ' /ay' : ' /mo'}`}/>
                          }
                          else if(item.paymentInterval === 'Yearly' && item.currencyCode === currencyCode){
                            return <PriceSelectionCard selected={priceSelected2} onClick={()=>setSubscriptionAnnual()} key={index}  price={`${item.currencyCode === 'TRY' ? item.price +'₺' : '$'+ item.price}`} currencyCode={item.currencyCode}  subscriptionType={t("pages.payment.form1.text2")}  paymentInterval={`${item.currencyCode === 'TRY' ? ' /yıl' : ' /yr'}`}/>
                          }
                          return null
                        }
                      )}
                      <Button 
                          sx={{
                            width: '300px', 
                            maxWidth: '300px', 
                            fontSize: 16, 
                            minWidth: '260px', 
                            alignSelf:'center', 
                            marginTop:'2vh',
                            height:'36px',
                            color:'white',
                            backgroundColor:'#009999',
                            borderRadius: 8,
                            '&:hover': {
                              boxShadow: "1px 1px 2px 2px #009999",
                              borderStyle:'none',
                              backgroundColor:'#009999'
                            },
                          }} 
                          disabled={selectedItem !== null ? false : true}
                          onClick={pricingSelectionNext}
                        >
                          {t("pages.payment.btn")} 
                      </Button>
                    </div>
                  </div>
                </Stepper.Step>
                {/* 2nd Step User Info */}
                <Stepper.Step  label={t("pages.payment.header.item2.text1")} sx={{color:'white', alignItems:'center' }} description={t("pages.payment.header.item2.text2")}>
                  <div className='flex flex-col items-center w-full '>
                    <div className='flex flex-col relative' >
                      <Formik
                        enableReinitialize
                        initialValues ={{
                          name: '',
                          // nickname: '',
                          identityNumber: '',
                          gsmNumber: '',
                          country: '',
                          notTurkishCitizen: false,
                        }}
                        validationSchema={
                          Yup.object({
                            name: Yup.string().min(2).required(t("pages.payment.form2.validation.input1")),
                            // nickname: Yup.string().min(3).required('Please enter your username!'),
                            identityNumber: Yup.string().notRequired().optional(),
                            gsmNumber: Yup.string().min(3).matches(/^(\d{1,4})?(?!0+\s+,?$)\d{10}\s*,?$/, t("pages.payment.form2.validation.input3reg")).required(t("pages.payment.form2.validation.input3")),
                            country: Yup.string().min(3).required(t("pages.payment.form2.validation.input4")),
                            notTurkishCitizen: Yup.boolean().required('Please enter your country!'),
                          })
                        }
                        onSubmit={async (
                          values: formValues1,
                          { setSubmitting, resetForm }: FormikHelpers<formValues1>
                          ) => {
                            //sends form values to the server then processes response data on handleSubmit function
                            try {
                              dispatch(loaderIsOn())
                              const payload = await updateUserInfo(values).unwrap();
                              setTimeout(() => {
                                successNotification(t(`components.successes.title`),t(`components.successes.payment.text1`))
                                resetForm()
                                nextStep()
                                dispatch(loaderIsOff())
                              }, 2000);
                              console.log('fulfilled ', payload);
                              //show success notification
                            } catch (error:any) {
                              
                              setTimeout(() => {
                                dispatch(loaderIsOff())
                              }, 2000);
                              errorNotification(t(`components.errors.title`),t(`components.errors.${error.data.errorCode.toString()}`))
                              console.error('rejected ', error);
                            }
                          } 
                        }
                      >
                        <Form>
                        
                        {/* <Field name="" {({field, form}:any)=>( )}  /> */}
                          <Field  name="name">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='name' 
                                type={'text'} 
                                error={form.touched.name && form.errors.name ? <ErrorMessage name={'name'} /> : null }
                                label={t("pages.payment.form2.text1")} 
                                placeholder="John Doe" 
                                required
                              />
                            )}
                          </Field>
                          {/* <Field  name="nickname">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='nickname' 
                                type={'text'} 
                                error={form.touched.nickname && form.errors.nickname ? <ErrorMessage name={'nickname'} /> : null }
                                label="Nickname" 
                                placeholder="John D." 
                                required
                              />
                            )}
                          </Field> */}
                          <Field  name="identityNumber">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='identityNumber' 
                                type={'text'} 
                                error={form.touched.identityNumber && form.errors.identityNumber ? <ErrorMessage name={'identityNumber'} /> : null }
                                label={t("pages.payment.form2.text2")} 
                                placeholder={'00011122233'}
                              />
                            )}
                          </Field>
                          <Field  name="gsmNumber">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='gsmNumber' 
                                type={'text'} 
                                error={form.touched.gsmNumber && form.errors.gsmNumber ? <ErrorMessage name={'gsmNumber'} /> : null }
                                label={t("pages.payment.form2.text3")} 
                                placeholder={'905355553535'}
                                required
                              />
                            )}
                          </Field>
                          <Field  name="country">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='country' 
                                type={'text'} 
                                error={form.touched.country && form.errors.country ? <ErrorMessage name={'country'} /> : null }
                                label={t("pages.payment.form2.text4")} 
                                placeholder={t("pages.payment.form2.placeholder4")} 
                                required
                              />
                            )}
                          </Field>
                          <Button 
                            sx={{width: '300px', 
                              fontSize: 16, 
                              minWidth: '260px', 
                              alignSelf:'center', 
                              marginTop:'2vh',
                              height:'36px',
                              color:'white',
                              backgroundColor:'#009999',
                              minInlineSize: '100%',
                              borderRadius: 8,
                              '&:hover': {
                                boxShadow: "1px 1px 2px 2px #009999",
                                borderStyle:'none',
                                backgroundColor:'#009999'
                              },
                            }} 
                            type='submit'
                          >
                            {t("pages.payment.btn")} 
                          </Button>
                            
                        </Form>
                      </Formik>

                    </div>
                  </div>
                </Stepper.Step>

                {/* 3rd Step Address Info */}
                <Stepper.Step  label={t("pages.payment.header.item3.text1")} sx={{color:'white'}} description={t("pages.payment.header.item3.text2")}>
                  <div className='flex flex-col items-center w-full'>
                    <div className='flex flex-col ' >
                      {/* Address Info */}
                      {addresses?.data.address ? 
                      <>
                        <PaymentAddressSelect/>
                        <Button 
                            sx={{width: '300px', 
                              fontSize: 16, 
                              minWidth: '260px', 
                              alignSelf:'center', 
                              marginTop:'2vh',
                              marginBottom:'1rem',
                              height:'36px',
                              color:'white',
                              backgroundColor:'#009999',
                              minInlineSize: '100%',
                              borderRadius: 8,
                              '&:hover': {
                                boxShadow: "1px 1px 2px 2px #009999",
                                borderStyle:'none',
                                backgroundColor:'#009999'
                              },
                            }}
                            disabled={selectedAddress ? false : true}
                            onClick={pricingSelectionNext}
                        >
                          {t("pages.payment.btn")} 
                        </Button> 
                        <hr className='my-4 border-1 border-dashed'></hr>
                      </>
                      :
                      <></>
                      }
                      <Text sx={{fontSize:'21px'}} >{t("pages.payment.form3.section2.text6")} </Text><br/>
                      {
                        showAddressForm === false ? 
                        <Button 
                          sx={{width: '300px', 
                            fontSize: 16, 
                            minWidth: '260px', 
                            alignSelf:'center', 
                            marginTop:'2vh',
                            height:'36px',
                            backgroundColor:'white',
                            color:'#009999',
                            minInlineSize: '100%',
                            borderRadius: 8,
                            '&:hover': {
                              boxShadow: "1px 1px 2px 2px white",
                              borderStyle:'none',
                              backgroundColor:'white',
                              color:'#009999'
                            },
                          }} 
                        onClick={()=>setShowAddressForm(true)}
                      >
                        {t("pages.payment.form3.section2.text7")}
                        </Button>  
                        :
                        <Formik
                          enableReinitialize
                          initialValues ={{
                            name: '',
                            address: '',
                            country: '',
                            city: '',
                            zipCode: ''
                          }}
                          validationSchema={
                            Yup.object({
                              name: Yup.string().min(3,t("pages.payment.form3.section2.validation.input1min")).max(20,t("pages.payment.form3.section2.validation.input1max")).required(t("pages.payment.form3.section2.validation.input1")),
                              address: Yup.string().min(3,t("pages.payment.form3.section2.validation.input2min")).max(60,t("pages.payment.form3.section2.validation.input2max")).required(t("pages.payment.form3.section2.validation.input2")),
                              city: Yup.string().min(3,t("pages.payment.form3.section2.validation.input3min")).max(20,t("pages.payment.form3.section2.validation.input3max")).required(t("pages.payment.form3.section2.validation.input3")),
                              country: Yup.string().min(3,t("pages.payment.form3.section2.validation.input4min")).max(20,t("pages.payment.form3.section2.validation.input4max")).required(t("pages.payment.form3.section2.validation.input4")),
                              zipCode: Yup.string().min(5,t("pages.payment.form3.section2.validation.input5min")).max(5,t("pages.payment.form3.section2.validation.input5max")).required(t("pages.payment.form3.section2.validation.input5")),
                            })
                          }
                          onSubmit={async (
                            values: formValues2,
                            { setSubmitting, resetForm }: FormikHelpers<formValues2>
                            ) => {
                              //sends form values to the server then processes response data on handleSubmit function
                              try {
                                dispatch(loaderIsOn())
                                const payload = await createNewAddress(values).unwrap();
                                setTimeout(() => {
                                  successNotification(t(`components.successes.title`),t(`components.successes.payment.text2`))
                                  resetForm()
                                  nextStep()
                                  dispatch(loaderIsOff())
                                  dispatch(setSelectedAddress(payload?.data?.address?.id))
                                }, 2000);
                                console.log('fulfilled ', payload);
                              } catch (error:any) {
                                console.error('rejected ', error);
                                setTimeout(() => {
                                  dispatch(loaderIsOff())
                                }, 2000);
                                errorNotification(t(`components.errors.title`),t(`components.errors.${error.data.errorCode.toString()}`))
                              }
                            } 
                          }
                        >
                          {({
                            isSubmitting, 
                            submitForm, 
                            isValid, 
                            dirty, 
                            errors, 
                            values
                          }: any) => (
                          <Form>
                          <Field name="name">
                            {({field, form}:any)=>( 
                              <PaymentTextInput 
                                {...field}
                                name='name'
                                error={form.touched.name && form.errors.name ? <ErrorMessage name={'name'} /> : null }
                                label={t("pages.payment.form3.section2.text1")}
                                placeholder={t("pages.payment.form3.section2.placeholder1")}
                                required 
                              /> 
                            )} 
                          </Field>
                          <Field name="address">
                            {({field, form}:any)=>( 
                              <TextAreaInput 
                                {...field}
                                name='address'
                                error={form.touched.address && form.errors.address ? <ErrorMessage name={'address'} /> : null }
                                placeholder="123 Center Ln.Plymouth, MN 55441"
                                label={t("pages.payment.form3.section2.text2")}
                                required 
                              /> 
                            )} 
                          </Field>
                          <Field name="country">
                            {({field, form}:any)=>( 
                              <PaymentTextInput 
                                {...field}
                                name='country'
                                error={form.touched.country && form.errors.country ? <ErrorMessage name={'country'} /> : null }
                                label={t("pages.payment.form3.section2.text3")}
                                placeholder={t("pages.payment.form3.section2.placeholder3")}
                                required 
                              /> 
                            )} 
                          </Field>
                          <Field name="city">
                            {({field, form}:any)=>( 
                              <PaymentTextInput 
                                {...field}
                                name='city'
                                error={form.touched.city && form.errors.city ? <ErrorMessage name={'city'} /> : null }
                                label={t("pages.payment.form3.section2.text4")}
                                placeholder={'Istanbul'}
                                required 
                              /> 
                            )} 
                          </Field>
                          <Field name="zipCode">
                            {({field, form}:any)=>( 
                              <PaymentTextInput 
                                {...field}
                                name='zipCode'
                                error={form.touched.zipCode && form.errors.zipCode ? <ErrorMessage name={'zipCode'} /> : null }
                                label={t("pages.payment.form3.section2.text5")}
                                placeholder={'06178'}
                                required 
                              /> 
                            )} 
                          </Field>
                          <Button 
                              sx={{width: '300px', 
                                fontSize: 16, 
                                minWidth: '260px', 
                                alignSelf:'center', 
                                marginTop:'2vh',
                                height:'36px',
                                color:'white',
                                backgroundColor:'#009999',
                                minInlineSize: '100%',
                                borderRadius: 8,
                                '&:hover': {
                                  boxShadow: "1px 1px 2px 2px #009999",
                                  borderStyle:'none',
                                  backgroundColor:'#009999'
                                },
                              }} 
                              type='submit'
                              loading={isSubmitting}
                              disabled={!isValid || !dirty || isSubmitting}
                            >
                              {t("pages.payment.btn")}
                          </Button>
                          </Form>
                        )}
                        </Formik>
                      }
                      {/* <PaymentAddressSelect data={addressData} /> */}
                    </div>
                  </div>
                </Stepper.Step>

                {/* 4thPayment Info */}
                <Stepper.Step  label={t("pages.payment.header.item4.text1")} sx={{color:'white', borderColor:''}} description={t("pages.payment.header.item4.text2")}>
                  <div className='flex flex-col items-center w-full'>
                    <div className='flex flex-col' >
                      {/* Payment Info */}
                      <Formik
                        enableReinitialize
                        initialValues ={{
                          cardHolderName: '',
                          cardNumber: '',
                          expireYear: '',
                          expireMonth: '',
                          cvc: '',
                          registerConsumerCard: true,
                          address: selectedAddress ? selectedAddress : addresses?.data?.address[0] ? addresses?.data?.address[0] : '',
                          pricingPlanReferenceCode: selectedItem ? selectedItem.pricingPlanReferenceCode.toString() : '59e6a66a-873f-4597-a6dd-7f0983d68db8' ,
                        }}
                        validationSchema={
                          Yup.object({
                            cardHolderName: Yup.string().min(2,t("pages.payment.form4.validation.input1min")).required(t("pages.payment.form4.validation.input1")),
                            cardNumber: Yup.string().required(t("pages.payment.form4.validation.input2")),
                            expireMonth: Yup.string().min(2,t("pages.payment.form4.validation.input3")).max(2,t("pages.payment.form4.validation.input3max")).required(t("pages.payment.form4.validation.input3")),
                            expireYear: Yup.string().min(2,t("pages.payment.form4.validation.input4min")).max(2,t("pages.payment.form4.validation.input4max")).required(t("pages.payment.form4.validation.input4")),
                            cvc: Yup.string().min(3,t("pages.payment.form4.validation.input5min")).max(3,t("pages.payment.form4.validation.input5max")).required(t("pages.payment.form4.validation.input5")),
                            registerConsumerCard: Yup.boolean(),
                            address: Yup.string().required('Please enter address!'),
                            pricingPlanReferenceCode: Yup.string().required('Please enter your zipCode!'),
                          })
                        }
                        onSubmit={async (
                          values: formValues3,
                          { setSubmitting, resetForm , submitForm}: FormikHelpers<formValues3>,
                          ) => {
                            //sends form values to the server then processes response data on handleSubmit function
                            try {
                              console.log("Son submitte")
                              dispatch(loaderIsOn())
                              const payload = await createSubscription(values).unwrap();
                              console.log('fulfilled ', payload);
                              setTimeout(() => {
                                successNotification(t(`components.successes.title`),t(`components.successes.payment.text3`))
                                nextStep()
                                resetForm()
                                dispatch(loaderIsOff())
                                
                              }, 2000);
                              setTimeout(()=>{
                                dispatch(deleteSelectedSubType())
                              },8000)
                            } catch (error:any) {
                              setTimeout(() => {
                                dispatch(loaderIsOff())
                              }, 2000);
                              errorNotification(t(`components.errors.title`),t(`components.errors.${error.data.errorCode.toString()}`))
                              console.error('rejected ', error);
                            }
                          } 
                        }

                      >
                      {({
                        isSubmitting, 
                        submitForm, 
                        isValid, 
                        dirty, 
                        errors, 
                        values
                      }: any) => (
                        <Form >
                          <Field  name="cardHolderName">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='cardHolderName' 
                                error={form.touched.cardHolderName && form.errors.cardHolderName ? <ErrorMessage name={'cardHolderName'} /> : null }
                                label={t("pages.payment.form4.text1")}
                                placeholder={'John Doe'}
                                required
                              />
                            )}
                          </Field>
                          <Field  name="cardNumber">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='cardNumber' 
                                error={form.touched.cardNumber && form.errors.cardNumber ? <ErrorMessage name={'cardNumber'} /> : null }
                                label={t("pages.payment.form4.text2")}
                                placeholder={'5526080000000006'}
                                required
                              />
                            )}
                          </Field>
                          <Field  name="expireMonth">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='expireMonth' 
                                error={form.touched.expireMonth && form.errors.expireMonth ? <ErrorMessage name={'expireMonth'} /> : null }
                                label={t("pages.payment.form4.text4")}
                                placeholder={'12'} 
                                required
                              />
                            )}
                          </Field>
                          <Field  name="expireYear">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='expireYear' 
                                error={form.touched.expireYear && form.errors.expireYear ? <ErrorMessage name={'expireYear'} /> : null }
                                label={t("pages.payment.form4.text3")}
                                placeholder={'23'} 
                                required
                              />
                            )}
                          </Field>
                          <Field  name="cvc">
                            {({field, form}:any)=>(
                              <PaymentTextInput 
                                {...field}
                                name='cvc' 
                                error={form.touched.cvc && form.errors.cvc ? <ErrorMessage name={'cvc'} /> : null }
                                label={t("pages.payment.form4.text5")}
                                placeholder={'999'} 
                                required
                              />
                            )}
                          </Field>
                          <Button 
                            sx={{width: '300px', 
                              fontSize: 16, 
                              minWidth: '260px', 
                              alignSelf:'center', 
                              marginTop:'2vh',
                              height:'36px',
                              color:'white',
                              backgroundColor:'#009999',
                              minInlineSize: '100%',
                              borderRadius: 8,
                              '&:hover': {
                                boxShadow: "1px 1px 2px 2px #009999",
                                borderStyle:'none',
                                backgroundColor:'#009999'
                              },
                            }}
                            loading={isSubmitting}
                            disabled={!isValid || !dirty || isSubmitting}
                            type='submit'
                          >
                            {t("pages.payment.form4.btn")}
                          </Button>
                        </Form>
                      )}
                      </Formik>
                    </div>
                  </div>
                </Stepper.Step>
                <Stepper.Completed>
                  <div className='flex flex-col items-center w-full'>
                  <img alt="gallery" className="w-1/2 object-cover h-auto object-center block bg-transparent" src={Confetti} />
                    {/* <Text fz={'xl'} align='center'> Purchase completed! <br/> You can access premium features of <Text span color={'teal'}>CereMeet</Text>.</Text> */}
                    <Text fz={'xl'} align='center'> {t("pages.payment.completed.text1")} <br/> {t("pages.payment.completed.text2")}</Text>
                  </div>
                </Stepper.Completed>
              </Stepper>
              {/* <div className='flex flex-row w-full justify-center items-start'>
                <span className='text-white mr-4'>Payment partner</span>
                <img alt='IyzicoLogo' className='w-20 h-auto' src={IyzicoLogo} />
              </div> */}
          
          {/* <Group position='center' w={'auto'}>
            <SaveButton onClick={nextStep} text='Next'/>
          </Group> */}
        </div>
        <BasketContainer />
      </div>
      
    </div>
    </>
  )
}

export default PaymentPage