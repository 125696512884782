import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import React from 'react'
import *  as Yup from 'yup'
import { loaderIsOff, loaderIsOn } from '../../../slices/modalSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import PaymentTextInput from '../FormComponents/PaymentTextInput';
import { Button, TextInput } from '@mantine/core';
import TextAreaInput from '../FormComponents/TextAreaInput';
import { useSendContactMessageMutation } from '../../../services/contactApi';
import { useTranslation } from 'react-i18next';

const validationSchema = Yup.object({
  email: Yup.string().email().required('Please enter Email!'),
  name: Yup.string().min(3).required('Please enter your name!'),
  message: Yup.string().min(10).max(200).required('Please enter your message!'),
  
})

interface formValues extends Yup.InferType<typeof validationSchema>{
  email: string;
  message: string;
  name: string;
}

const NewContactForm = () => {

  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useAppDispatch()

  const [sendContactMessage, response] = useSendContactMessageMutation()

  const successNotification = (title:string,message:string) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.green[6],
          borderColor: theme.colors.green[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.green[7] },
        },
      }),
    })
  }

  const errorNotification = (title:string,message:any) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[6],
          borderColor: theme.colors.red[6],
          '&::before': { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.red[7] },
        },
      }),
    })
  }

  return (
    <Formik 
    enableReinitialize
    initialValues ={{
      email: '',
      name: '',
      message: '',
    }}
    validationSchema={
      Yup.object({
        name: Yup.string().required(t("components.forms.register.validation.input1")),
        email: Yup.string().email(t("components.forms.register.validation.input2format")).required(t("components.forms.register.validation.input2")),
        message: Yup.string().min(10).max(200).required(t("components.forms.contact.validation.input3")),
        
      })
    }
    onSubmit={async (
      values: formValues,
      { setSubmitting, resetForm , submitForm}: FormikHelpers<formValues>,
      ) => {
        //sends form values to the server then processes response data on handleSubmit function
        try {
          console.log("Son submitte")
          const payload = await sendContactMessage(values).unwrap();
          setTimeout(() => {
            successNotification(t(`components.successes.title`),t(`components.successes.contact.text1`))
            resetForm()
          }, 2000);
          // console.log('fulfilled ', payload);
        } catch (error:any) {
          errorNotification(t(`components.errors.title`),t(`components.errors.${error.data.errorCode.toString()}`))
          console.error('rejected ', error);
        }
      } 
    }
    >
      {({
        isSubmitting, 
        submitForm, 
        isValid, 
        dirty, 
        errors, 
        values
      }: any) => (
        <div className="lg:w-1/2 md:w-2/3 w-full mx-auto z-50 ">
          <div className="flex flex-wrap justify-center -m-2">
            <Form >
              <Field  name="name">
                {({field, form}:any)=>(
                  <div className="w-full">
                    <PaymentTextInput 
                      {...field}
                      name='name'
                      sx={{width:'full', }}
                      error={form.touched.name && form.errors.name ? <ErrorMessage name={'name'} /> : null }
                      label={t("components.forms.contact.text1")} 
                      placeholder={'John Doe'}
                      required
                    />
                  </div>
                )}
              </Field>
              <Field  name="email">
                {({field, form}:any)=>(
                  <div className="w-full">
                    <PaymentTextInput 
                      {...field}
                      name='email' 
                      error={form.touched.email && form.errors.email ? <ErrorMessage name={'email'} /> : null }
                      label={t("components.forms.contact.text2")} 
                      placeholder={'mail@ceremeet.com'}
                      required
                    />
                  </div>
                )}
              </Field>
              <Field  name="message">
                {({field, form}:any)=>(
                   <div className="w-full">
                    <TextAreaInput 
                      {...field}
                      name='message' 
                      error={form.touched.message && form.errors.message ? <ErrorMessage name={'message'} /> : null }
                      label={t("components.forms.contact.text3")} 
                      placeholder={t("components.forms.contact.text4")} 
                      required
                    />
                  </div>
                )}
              </Field>
              <div className=" w-full">
                <Button 
                  sx={{width: '300px', 
                    fontSize: 16, 
                    minWidth: '260px', 
                    alignSelf:'center', 
                    marginTop:'2vh',
                    height:'36px',
                    color:'white',
                    backgroundColor:'#009999',
                    minInlineSize: '100%',
                    borderRadius: 8,
                    '&:hover': {
                      boxShadow: "1px 1px 2px 2px #009999",
                      borderStyle:'none',
                      backgroundColor:'#009999'
                    },
                  }}
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                  type='submit'
                >
                  {t("components.forms.contact.button")}
                </Button>
              </div>              
            </Form>
          </div>
        </div>
      )}
    </Formik>
  )
}

export default NewContactForm