import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../app/store';
import { IAuthResponse, IForgotPassword, IGenericResponse, ILoginUser, IRegisterUser, IUpdatePassword } from './types';
import { deleteToken, setAuthCredentials } from '../slices/authSlice';
import { userInfoPopoverIsOff } from '../slices/modalSlice';
import { deleteSelectedSubType } from '../slices/pricesSlice';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/auth/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).authState.access_token
      if( token ){
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    // Types Added
    registerUser: builder.mutation<IGenericResponse, IRegisterUser>({
      query(data) {
        return {
          url: 'register',
          method: 'POST',
          body: data,
        };
      },
    }),
    // Types Added
    loginUser: builder.mutation<IAuthResponse, ILoginUser>({
      query(data) {
        return {
          url: 'login',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const payload =  await queryFulfilled.then( result => result.data );
          console.log(payload.status, payload.access_token, payload.refresh_token)
          dispatch(setAuthCredentials(payload))
        } catch (error) {
          console.log(error)
        }
      },
    }),

    // Types Added
    logoutUser: builder.mutation<any, void>({
      query() {
        return {
          url: 'logout',
          credentials: 'include',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled.then( result => result.data );
          dispatch(userInfoPopoverIsOff())
          dispatch(deleteToken())
          dispatch(deleteSelectedSubType())
        } catch (error) {
          console.log(error)
        }
      },
    }),

    verifyEmail: builder.mutation< IGenericResponse, { verificationCode: string | undefined }>({
      query({ verificationCode }) {
        return {
          url: `verifyemail/${verificationCode}`,
          method: 'GET',
        };
      },
    }),

    getNewAccessToken:builder.mutation<{ access_token: string; status: string },any>({
      query(data) {
        return {
          url: 'refresh',
          method: 'GET',
          body: data,
          credentials: 'include'
        };
      },
    }),


    forgotPassword: builder.mutation<any, {email:string | null | undefined}>({
      query(data) {
        return {
          url: 'forgotpass',
          method: 'POST',
          body: data,
        };
      },
    }),

    updatePassword: builder.mutation<IGenericResponse, IUpdatePassword>({
      query(data) {
        return {
          url: 'updatepassword',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useGetNewAccessTokenMutation,
  useVerifyEmailMutation,
} = authApi;
