import React from 'react'
import { useTranslation } from 'react-i18next';

const NewHeroSection = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="text-gray-600 body-font z-50 h-screen min-h-screen snap-center relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20 sm:mt-12 -mt-4">
          <h1 className="sm:text-6xl text-5xl font-extrabold  title-font text-white mb-8 ">{t("pages.home.hero.header.title1")}<br/><span className='text-[#009999]'>{t("pages.home.hero.header.title2")}</span> <br/>{t("pages.home.hero.header.title3")}{' '} </h1>
          <p className="sm:text-xl text-xl leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-100 mb-8">{t("pages.home.hero.description")}</p>
          <div className=" justify-center hidden sm:flex">
            <a href='https://pdate.ceremeet.com/launchersetup2/setup.exe'>
              <button className="inline-flex  text-white bg-[#009999] border-0 py-2 px-6 focus:outline-none hover:bg-[#009999] hover:bg-opacity-95 rounded text-lg">Windows</button>
            </a>
            <a href='https://pdate.ceremeet.com/mac/Ceremeet-Installer.dmg'>
              <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Mac</button>
            </a>
           </div>
        </div>
        
      </div>
    </section>
  )
}

export default NewHeroSection