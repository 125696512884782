import { Container, Title, Accordion, createStyles } from '@mantine/core';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
  wrapper: {
    // paddingTop: theme.spacing.xl * 1.5,
    
    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 1.5,
      minWidth: 'full',
      width: '100vw',
      marginTop: '4rem',
      zIndex: 10,
    },
    [theme.fn.largerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 1.5,
      minHeight: 650,
      minWidth: 650,
      width: '60vw',
      maxWidth: 800,
      marginTop: '4rem',
      zIndex: 10,
    },
  },

  title: {
    marginBottom: theme.spacing.xl * 1.6,
    color: 'white'
  },

  item: {
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.lg,
    // backgroundColor: '#a64dff',

    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },
  
}));

const faqENData = [
  {
    question : 'How can I create a CereMeet user account? ',
    answer : 'You can create user account from our register page.'
  },
  {
    question : 'Do I need an account to use CereMeet?',
    answer : ' In order to fully utilize the features of CereMeet, an account is necessary. This will allow you to create an avatar, schedule and host your own meetings, and send invitations to participants'
  },
  {
    question : 'How can I reset my password?',
    answer : 'You can reset your password at ceremeet.com/forgotpassword'
  },
  {
    question : 'Can I create more than one CereMeet account?',
    answer : 'Yes, it is possible to create multiple CereMeet accounts. However, it is recommended to use one account per person for better organization and management of meetings and participants.'
  },
  {
    question : ' Where do I download the latest version of CereMeet?',
    answer : 'You can download the latest version of CereMeet from ceremeet.com'
  },
  {
    question : 'How do I invite others to join my meeting?',
    answer : 'You can invite others to join your meeting by copying the join URL or meeting invitation and sending it out via email. '
  },
  {
    question : 'Do I need an account to join a meeting in CereMeet?',
    answer : 'Yes, in order to join a meeting in CereMeet, you will need to create your own user account and avatar. This is to ensure that you have a unique identity within the platform and can participate in meetings as yourself. '
  },
  {
    question : 'What payment systems do you work with?',
    answer : 'We accept American Express, Visa, and Mastercard credit card payments via iyzico. We do not accept debit card payments. '
  },
  {
    question : 'Do you store my credit card information?',
    answer : 'No, we do not store your credit card information. After payments are made, your credit card information is securely stored by our payment processing partner, iyzico.'
  },
]

const faqTRData = [
  {
    question : 'CereMeet hesabı nasıl oluşturulur? ',
    answer : 'Kayıt ol sayfamızdan CereMeet hesabı oluşturabilirsiniz.'
  },
  {
    question : "CereMeet'i kullanmak için hesaba ihtiyacım var mı?",
     answer : "CereMeet'i kullanmak ve sağladığı özelliklerden faydalanmak için hesaba ihtiyacınız var. Hesap oluşturduktan sonra avatar yaratma, toplantı oluşturma-yönetme ve başkalarıyla paylaşma gibi birçok özellikten faydalanabilirsiniz."
  },
  {
    question : 'Şifremi nasıl yenileyebilirim?',
    answer : 'Şifrenizi ceremeet.com/forgotpassword adresinden yenileyebilirsiniz.'
  },
  {
    question : 'Birden fazla CereMeet hesabı oluşturabilir miyim?',
    answer : 'Birden fazla hesap oluşturmanız mümkün fakat daha efektif toplantı organizasyonu ve yönetimi için önerimiz tek hesap kullanmanızdır.'
  },
  {
    question : "CereMeet'in en güncel versiyonunu nasıl indirebilirim?",
    answer : 'Anasayfamızdaki Windows ve Mac butonlarından işletim sisteminize uygun olan en güncel versiyonu indirebilirsiniz.'
  },
  {
    question : 'Başkalarını toplantıya nasıl davet edebilirim??',
     answer : 'Toplantı katılım bağlantısını paylaşarak başkalarını toplantılara davet edebiliriniz.'
  },
  {
    question : "CereMeet'de toplantıya katılmak için üye olmam gerekiyor mu?",
    answer : "Evet, toplantılara katılabilmek için CereMeet'e üye olmanız gerekiyor."
  },
  {
    question : 'Hangi ödeme sisteleri ile çalışıyorsunuz?',
    answer : 'İyzico aracılığı ile American Express, Visa, ve Mastercard entegre kredi kartlarından ödeme alabiliyoruz. Banka kartlarından ödeme alamıyoruz.'
  },
  {
    question : 'Kredi kartı bilgilerimi saklıyor musunuz?',
    answer : 'Hayır, kredi kartı bilgilerinizi saklamıyoruz. Ödeme tamamlandıktan sonra, ödeme partnerimiz İyzico ödeme bilgilerinizi güvenli bir şekilde saklıyor.'
  },
]

const placeholder =
  'It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.It was born from sludge on the ocean floor. In a sterile environment, the germs within its body can’t multiply, and it dies.It has no eyeballs, so it can’t see. It checks its surroundings via the ultrasonic waves it emits from its mouth.';

export function Faq() {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Container className={classes.wrapper}>
      <Title align="center" className={classes.title}>
        {t("pages.faq.text1")}
      </Title>

      <Accordion variant="separated" multiple={false}>
        {
          i18n.resolvedLanguage === 'en' ?
            faqENData.map((item,index)=>{
              return (
                <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                  <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                  <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              )
            })
            :
            faqTRData.map((item,index)=>{
              return (
                <Accordion.Item key={index+1000} className={classes.item} value={`${index}`}>
                  <Accordion.Control sx={{backgroundColor:'a64dff'}}>{item.question}</Accordion.Control>
                  <Accordion.Panel sx={{backgroundColor:'a64dff'}}>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              )
            })
        }
        

      </Accordion>
    </Container>
  );
}