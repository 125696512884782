import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// For further development convert the object in the init function to JSON file.
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          pages: {
            home: {
              hero: {
                header: {
                  title1: "CereMeet is a ",
                  title2: "productivity tool",
                  title3: "in the metaverse.",
                },
                description:
                  "CereMeet gathers the capabilities of traditional communication platforms in a highly realistic 3D environment with photorealistic avatars.",
              },
              features: {
                header: {
                  title: "Communication Platform",
                  description:
                    "CereMeet surpasses the common comminication tools with meeting rooms that provide PDF and video presentation support, screen sharing features, and calendar integration. CereMeet also holds an interactive customizable product showrooms.",
                },
                feature1: {
                  title: "Custom Photo Realistic Avatars",
                  description:
                    "Attend your meetings with photo-realistic digital versions of yourself or a fully customizable character that express your digital presence.​",
                },
                feature2: {
                  title: "Make Stunning Presentations",
                  description:
                    "Share PDFs, show videos, and share your computer screen on the in-app screens to impress your clients.​",
                },
                feature3: {
                  title: "3D Meeting Experience",
                  description:
                    "You can create meetings with up to 100 people per meeting in real-time. The meeting rooms could be customized with brand logos, videos, presentations, and 3D assets.​",
                },
              },
              statistics: {
                header: {
                  title: "Productivity Tool",
                  description:
                    "CereMeet provides a highly customizable 3D environment that enable users to express them selves in the metaverse.",
                },
                stats: {
                  text1: "Downloads",
                  text2: "Corporate Customers",
                  text3: "Spent in Meetings",
                  text4: "Meeting Created",
                },
              },
              gallery: {
                header: {
                  title: "Out of Box Meetings",
                  description:
                    "No need to feel boxed in just one small meeting room! In CereMeet, we have three different meeting rooms and one big meeting hall area that you can customize to your heart's content. Create your virtual twin yourself and start your meeting now!",
                },
              },
              pricing: {
                header: {
                  title: "Pricing",
                  description:
                    "Choose one of our membership packages below and start experiencing CereMeet!",
                },
                card1: {
                  header: {
                    title1: "Start",
                    title2: "Free",
                  },
                  items: {
                    text1: "Customizable avatars",
                    text2: "8 minutes of the trial version",
                    text3: "Customizable presentation screen",
                  },
                },
                card2: {
                  header: {
                    text1: "POPULAR",
                    title1: "MONTHLY",
                    title2: "/mo",
                  },
                  items: {
                    text1: "Customizable avatars",
                    text2: "Meetings with up to 100 people",
                    text3: "Unlimited time per meeting",
                    text4: "Customizable presentation and video screens",
                    text5: "Buy Now",
                  },
                },
                card3: {
                  header: {
                    title1: "ANNUAL",
                    title2: "/yr",
                  },
                  items: {
                    text1: "Customizable avatars",
                    text2: "Meetings with up to 100 people",
                    text3: "Unlimited time per meeting",
                    text4: "Customizable presentation and video screens",
                    text5: "Customizable virtual showrooms",
                    text6: "Buy Now",
                  },
                },
                card4: {
                  header: {
                    title1: "CONTACT US",
                    title2: "Enterprise",
                  },
                  items: {
                    text1: "Cloud or on-premise installation",
                    text2: "Meetings with up to 100 people",
                    text3: "Unlimited time per meeting",
                    text4: "Customizable presentation and video screens",
                    text5: "Customizable virtual showrooms",
                    text6: "Customizable avatars",
                  },
                },
                refund: {
                  text1:
                    "You can get your full refund if you decide to cancel your subscription within 72 hours after your first purchase.",
                },
              },
              contact: {
                header: {
                  title: "Contact Us",
                  description:
                    "For further questions, please send us a message. Our team will get back to you within 24 hours.",
                },
              },
            },
            faq: {
              text1: "Frequently Asked Questions",
            },
            payment: {
              header: {
                item1: {
                  text1: "Subscription",
                  text2: "First Step",
                },
                item2: {
                  text1: "User Info",
                  text2: "Second Step",
                },
                item3: {
                  text1: "Address",
                  text2: "Third Step",
                },
                item4: {
                  text1: "Purchase",
                  text2: "Final Step",
                },
              },
              form1: {
                text1: "Monthly",
                text2: "Annual",
                text3: "Subscription",
              },
              form2: {
                text1: "Fullname",
                text2: "VAT Number",
                text3: "GSM Number",
                text4: "Country",
                placeholder4: "Spain",
                validation: {
                  input1: "Please enter your name!",
                  input3: "Please enter your GSM Number!",
                  input3reg: "Type your number without + or any space",
                  input4: "Please enter your country!",
                  input5: "",
                },
              },
              form3: {
                section1: {
                  text1: "Select Existing Address",
                  text2: "Pick Address",
                },
                section2: {
                  text1: "Address Name",
                  text2: "Address",
                  text3: "Country",
                  text4: "City",
                  text5: "Zip Code",
                  text6: "New Address",
                  text7: "Add Address",
                  placeholder1: "Home",
                  placeholder3: "Turkey",
                  validation: {
                    input1: "Please enter address name!",
                    input1min: "Address name must be at least 3 character",
                    input1max: "Address name must be at most 20 character",

                    input2: "Please enter address text!",
                    input2min: "Address text must be at least 3 character ",
                    input2max: "Address text must be at most 60 character",

                    input3: "Please enter your city!",
                    input3min: "City must be at least 3 character!",
                    input3max: "City must be at most 20 character",

                    input4: "Please enter your country!",
                    input4min: "Country must be at least 3 character ",
                    input4max: "Country must be at most 20 character",

                    input5: "Please enter your Zip code!",
                    input5min: "Zip code must be at least 5 character !",
                    input5max: "Zip code must be at most 5 character !!",
                    input6: "",
                  },
                },
              },
              form4: {
                text1: "Cardholder Name",
                text2: "Card Number",
                text3: "Expire Year",
                text4: "Expire Month",
                text5: "CVC",
                btn: "Buy",
                validation: {
                  input1: "Please enter Cardholder name!",
                  input1min: "Cardholder name must be at least 2 character",

                  input2: "Please enter card number!",

                  input3: "Please enter your expire month!",
                  input3min: "Expire month must be at least 2 character!",
                  input3max: "Expire month must be at most 2 character",

                  input4: "Please enter your expire year!",
                  input4min: "Expire year must be at least 2 character ",
                  input4max: "Expire year must be at most 2 character",

                  input5: "Please enter your cvc!",
                  input5min: "Cvc must be at least 3 character !",
                  input5max: "Cvc must be at most 3 character !!",
                  input6: "",
                },
              },
              completed: {
                text1: "Purchase completed!",
                text2: "You can access premium features of CereMeet.",
              },
              card: {
                text1: "My Card",
                text2: "Total",
              },
              btn: "Next",
            },
            pagenotfound: {},
          },
          components: {
            forms: {
              login: {
                header: "Login to Ceremeet",
                inputEmail: "Email",
                inputPwd: "Password",
                placeholder1: "Your Password",
                text1: "Remember me",
                text2: "Forgot password?",
                text3: "Do not have an account yet?",
                text4: "Create account",
                button: {
                  btnSubmit: "Log in",
                  btnGoogle: 'Sign in with Google',
                },
                validation: {
                  input1: "Please enter your email address!",
                  input1format: "Email format is not correct!",
                  input2: "Please enter your password!",
                  input2min: "Your password must be at least 8 characters",
                  input2max: "Your password must be at most 25 characters",
                  input3: "",
                },
              },
              register: {
                header: "Sign up to CereMeet",
                inputEmail: "Email",
                inputName: "Fullname",
                inputPwd: "Password",
                inputConPwd: "Confirm Password",
                placeholder1: "Your Password",
                placeholder2: "Re-enter password",
                button: {
                  btnSubmit: "Sign up",
                  btnGoogle: "Sign up with Google"
                },
                validation: {
                  input1: "Please enter your name!",
                  input2: "Please enter your email address!",
                  input2format: "Email format is not correct!",
                  input3: "Please enter your password!",
                  input3min: "Your password must be at least 8 characters",
                  input3max: "Your password must be at most 25 characters",
                  input4: "Please re-enter your password",
                  input5: "Passwords do not match!",
                  input6: "",
                },
              },
              forgot: {
                header: "Get Recovery Code",
                inputEmail: "Email",
                inputPwd: "Password",
                inputConPwd: "Confirm Password",
                placeholder1: "Your Password",
                placeholder2: "Code",
                button: {
                  btnSubmit: "Submit",
                },
                header2: "Reset Password",
                text1: "Reset Password",
              },
              contact: {
                text1: "Name",
                text2: "Email",
                text3: "Message",
                text4: "Your Message",
                button: "Submit",
                validation: {
                  input3: "Please enter your message!",
                },
              },
            },
            secondHero: {
              header: {
                title:
                  "CereMeet is a productivity and communication platform in the metaverse.",
                description:
                  "CereMeet gathers the capabilities of traditional communication platforms in a highly realistic 3D environment with photorealistic avatars.",
                button: {
                  btnSubmit: "Get Started",
                },
              },
            },
            navbar: {
              main: {
                text1: "Pricing",
                text2: "Contact Us",
                text3: "FAQ",
                text4: "Guidline",
                text5: "Cere",
                link: "https://pdate.ceremeet.com/ceremeetPictures/pdf/CereMeet_Guideline.pdf",
              },
              right: {
                text1: "Log in",
                text2: "Sign up",
              },
              drawer: {
                text1: "Home",
                text2: "Pricing",
                text3: "Contact Us",
                text4: "FAQ",
                text5: "Guidline",
                link: "https://pdate.ceremeet.com/ceremeetPictures/pdf/CereMeet_Guideline.pdf",
              },
              modal1: {
                text1: "You are going to downgrade your CereMeet account.",
                text2: "Are you sure?",
                text3: "Remove Subscription",
                btn1: "Unsubscribe",
                btn2: "Back",
              },
              modal2: {
                text1: "To buy CereMeet products",
                text2: "You need to login!",
                text3: "Please Login",
                btn1: "Login",
                btn2: "Back",
              },
              profile: {
                text1: "Loading...",
                text2: "To access your profile, please log in!",
                btn1: "Manage Membership",
                btn2: "Update Password",
                btn3: "Log Out",
                btn4: "Unsubscribe ",
                btn5: "Back",
              },
              popover1: {
                text1: "There are no items selected!",
                btn1: "Purchase Now",
              },
            },
            footer: {
              text1: "Privacy Policy",
              text2: "Terms of Service",
            },
            errors: {
              title: "Error",
              404: "Page cannot be found",
              1001: "Address with that ID not found",
              1002: "You are not authorized to delete this address",
              1003: "You are not authorized to display this address",
              1004: "Address with that name already exist",
              1005: "Address with that title already exist",
              1101: "Validation error",
              2001: "Invalid email or password",
              2002: "You are not verified, check your email to verify your account",
              2003: "Invalid email or password",
              2004: "Invalid email",
              2006: "Could not verify email",
              2007: "Could not refresh access token",
              2008: "User with that email already exist",
              2009: "Your password reset code is not valid.",
              3001: "There was an error sending email, please try again",
              4001: "Meeting with that ID not found",
              5001: "AI quota exceeded",
              5002: "Conversation limit exceeded",
              5003: "Bot with that ID not found",
              5004: "AI response error",
              5005: "The question violates our terms of service",
              6001: "User with that ID not found",
              6002: "Username already exists",
              7001: "You are not logged in",
              7002: "Invalid token or user doesn't exist",
              7003: "Invalid token or session has expired",
              7004: "Session has expired or user doesn't exist",
              9001: "You already have a subscription",
              9002: "You dont have a subscription",
              100001: "System error",
              100310: "Invalid token.",
              100311: "Access token expired.",
              100312: "Authentication error.",
              100300: "You do not have access to merchant information.",
              200301: "Email is required.",
              200302: "Email should include less than {0} characters.",
              200303: "Email format is invalid.",
              200304: "Identity number is required.",
              200305:
                "Identity number should include less than {0} characters.",
              200306:
                "Identity number should include more than {0} characters.",
              200310: "Phone number is invalid.",
              200311: "Phone number should include less than {0} characters.",
              200320: "Pagination request not valid.",
              200500: "Product name is required.",
              200501: "At least one parameter is required.",
              200600: "Pricing plan name is required.",
              200601: "Pricing plan price is required.",
              200602: "Pricing plan price is invalid.",
              200603: "Payment Interval is required.",
              200604: "Payment Interval is invalid.",
              200605: "currencyCode is required.",
              200606: "PlanPaymentType is required",
              200607: "PlanPaymentType is invalid",
              200608: "Trial period is invalid.",
              200610: "Reference code is required.",
              200611: "Payment interval count is invalid.",
              200700: "Customer name is required.",
              200701: "Customer surname is required.",
              200702: "Customer phone number is required.",
              200703: "Billing address is required.",
              200710: "At least one parameter is required.",
              200711: "Reference code is required",
              200800: "Address is required.",
              200801: "Address should include less than {0} characters.",
              200802: "Contact Name is required.",
              200803: "Contact Name should include less than {0} characters.",
              200804: "City is required.",
              200805: "City should include less than {0} characters.",
              200806: "Country is required.",
              200807: "Country should include less than {0} characters",
              200808: "Zip Code should include less than {0} characters.",
              200900: "Reference code is required.",
              200901: "Reference code is required.",
              200902: "Subscription status is invalid.",
              201550: "Price is required.",
              201551: "Price should be more than zero.",
              201000: "Product is not found.",
              201001: "Product already exists.",
              201002: "Active product is not found.",
              201500: "Pricing plan is not found.",
              201501: "Pricing plan already exists.",
              201052: "Active pricing plan is not found",
              201053: "Pricing plan is not suitable to be deleted",
              201054: "Pricing plan is not active.",
              201100: "Customer is not found.",
              201101: "Customer already exists.",
              201102: "Customer is not active.",
              201103: "Customer should have card.",
              201400: "Subscription is not found.",
              201401: "Subscription is not allowed to activate.",
              201402: "Subscription is not allowed to upgrade.",
              201403: "Subscription is not allowed to cancel.",
              201404: "Subscription has already same pricing plan.",
              201405:
                "Subscription upgrade is not allowed to different product.",
              201406:
                "Subscription can not be upgraded to a pricing plan with different payment interval.",
              201450: "Subscription order is not found.",
              200750: "Card is required",
              200751: "Card should be allowed to be registered.",
              200903: "Subscription reference code is required.",
              200905: "Subscription status is invalid for card update.",
              201553: "Card is not belong to customer.",
              201451: "Subscription payment not suitable for retry.",
              201600: "Checkoutform is not found.",
              201601: "Checkoutform not completed.",
              201700: "Recurring payment attempt not found!",
              201900: "Currency is not found.",
            },
            successes: {
              title: "Success",
              payment: {
                text1: "Profile info updated.",
                text2: "Address info updated.",
                text3: "Payment received.",
              },
              login: {
                text1: "You are logged in.",
              },
              register: {
                text1: "Profile created.",
              },
              forgot: {
                text1: "Renewal code sent to your email address.",
                text2: "Your password has been renewed.",
              },
              contact: {
                text1: "Message received.",
              },
            },
          },
        },
      },
      tr: {
        translation: {
          pages: {
            home: {
              hero: {
                header: {
                  title1: "CereMeet metaverse’de bulunan bir ",
                  title2: "üretim aracıdır.",
                  title3: "",
                },
                description:
                  "CereMeet, geleneksel iletişim platformlarının yeteneklerini fotogerçekçi avatarlarla son derece gerçekçi bir 3B ortamda bir araya getirir.",
              },
              features: {
                header: {
                  title: "İletişim Platformu",
                  description:
                    "CereMeet, PDF ve video sunum desteği, ekran paylaşım özellikleri ve takvim entegrasyonu sağlayan toplantı odaları ile yaygın iletişim araçlarını geride bırakıyor. CereMeet ayrıca etkileşimli özelleştirilebilir showroomlara sahiptir.",
                },
                feature1: {
                  title: "Özel Fotogerçekçi Avatarlar",
                  description:
                    "Toplantılarınıza fotogerçekçi dijital versiyonlarınızla veya dijital varlığınızı temsil edecek tamamen özelleştirilebilir karakterlerle katılın.​",
                },
                feature2: {
                  title: "Etkileyici Sunumlar Yapın",
                  description:
                    "Müşterilerinizi etkilemek için uygulama içi ekranlarda PDF'ler paylaşın, videolar gösterin ve bilgisayar ekranınızı paylaşın.",
                },
                feature3: {
                  title: "3B Toplantı Deneyimi",
                  description:
                    "​Gerçek zamanlı olarak toplantı başına 100 kişiye kadar toplantılar oluşturabilirsiniz. Toplantı odalarını marka logoları, videolar, sunumlar ve 3B varlıklar ile özelleştirebilirsiniz.",
                },
              },
              statistics: {
                header: {
                  title: "Üretkenlik Aracı",
                  description:
                    "CereMeet, kullanıcıların metaverse'te kendilerini ifade etmelerini sağlayan, son derece özelleştirilebilir bir 3B ortam sağlar.",
                },
                stats: {
                  text1: "İndirme",
                  text2: "Kurumsal Müşteri",
                  text3: "Toplantılarda Geçirilen Süre",
                  text4: "Düzenlenen Toplantı Sayısı",
                },
              },
              gallery: {
                header: {
                  title: "Sıra Dışı Toplantılar",
                  description:
                    "Küçük bir toplantı odasına tıkılmaya gerek yok! CereMeet'te, dilediğiniz gibi özelleştirebileceğiniz üç farklı toplantı salonumuz ve bir büyük toplanma alanımız var. Sanal ikizinizi oluşturun ve hemen toplantıya başlayın!",
                },
              },
              pricing: {
                header: {
                  title: "Fiyatlandırma",
                  description:
                    "Aşağıdaki üyelik paketlerimizden birini seçin ve CereMeet'i deneyimlemeye başlayın!",
                },
                card1: {
                  header: {
                    title1: "BAŞLANGIÇ",
                    title2: "Ücretsiz",
                  },
                  items: {
                    text1: "Özelleştirilebilir avatarlar",
                    text2: "8 dakikalık deneme versiyonu",
                    text3: "Özelleştirilebilir sunum ekranı",
                  },
                },
                card2: {
                  header: {
                    text1: "POPÜLER",
                    title1: "AYLIK",
                    title2: "/ay",
                  },
                  items: {
                    text1: "Özelleştirilebilir avatarlar",
                    text2: "100 kişiye kadar yapılabilen toplantılar",
                    text3: "Toplantı başına sınırsız toplantı süresi",
                    text4: "Özelleştirilebilir sunum ekranları",
                    text5: "Satın Al",
                  },
                },
                card3: {
                  header: {
                    title1: "YILLIK",
                    title2: "/yıl",
                  },
                  items: {
                    text1: "Özelleştirilebilir avatarlar",
                    text2: "100 kişiye kadar yapılabilen toplantılar",
                    text3: "Toplantı başına sınırsız toplantı süresi",
                    text4: "Özelleştirilebilir sunum ekranları",
                    text5: "Özelleştirilebilir sanal showroomlar",
                    text6: "Satın Al",
                  },
                },
                card4: {
                  header: {
                    title1: "BİZE ULAŞIN",
                    title2: "Kurumsal",
                  },
                  items: {
                    text1: "Özelleştirilebilir avatarlar",
                    text2: "100 kişiye kadar yapılabilen toplantılar",
                    text3: "Toplantı başına sınırsız toplantı süresi",
                    text4: "Özelleştirilebilir sunum ekranları",
                    text5: "Özelleştirilebilir sanal showroomlar",
                    text6: "7/24 aktif destek",
                  },
                },
                refund: {
                  text1:
                    "İlk satın alma işleminizden sonraki 72 saat içerisinde aboneliğinizi iptal etmeye karar verirseniz, paranızın tamamını geri alabilirsiniz.",
                },
              },
              contact: {
                header: {
                  title: "Bize Ulaşın",
                  description:
                    "Diğer sorularınız için bizlere mesaj gönderebilirsiniz. Ekibimiz size 24 saat içerisinde dönüş yapacaktır.",
                },
              },
            },
            faq: {
              text1: "Sık Sorulan Sorular",
            },
            payment: {
              header: {
                item1: {
                  text1: "Üyelik",
                  text2: "İlk Adım",
                },
                item2: {
                  text1: "Kullanıcı Bilgileri",
                  text2: "İkinci Adım",
                },
                item3: {
                  text1: "Adres",
                  text2: "Üçüncü Adım",
                },
                item4: {
                  text1: "Alım",
                  text2: "Son Adım",
                },
              },
              form1: {
                text1: "Aylık ",
                text2: "Yıllık ",
                text3: "Üyelik",
              },
              form2: {
                text1: "Tam Ad",
                text2: "TC Kimlik Numarası",
                text3: "Telefon Numarası",
                text4: "Ülke",
                placeholder4: "İspanya",
                validation: {
                  input1: "Lütfen adınızı giriniz!",
                  input3: "Lütfen telefon numaranızı giriniz!",
                  input3reg: "Numaranızı + veya boşluk olmadan yazın",
                  input4: "Lütfen ülkenizi girin!",
                  input5: "",
                },
              },
              form3: {
                section1: {
                  text1: "Kayıtlı Adreslerden Seç",
                  text2: "Adres Seç",
                },
                section2: {
                  text1: "Adresiniz",
                  text2: "Tam Adres",
                  text3: "Ülke",
                  text4: "Şehir",
                  text5: "Zip Kodu",
                  text6: "Yeni Adres Ekle",
                  text7: "Adres Ekle",
                  placeholder1: "Ev",
                  placeholder3: "Turkiye",
                  validation: {
                    input1: "Lütfen adres adını giriniz!",
                    input1min: "Adres adı en az 3 karakter olmalıdır",
                    input1max: "Adres adı en fazla 20 karakter olmalıdır",

                    input2: "Lütfen adres metnini giriniz!",
                    input2min: "Adres metni en az 3 karakter olmalıdır",
                    input2max: "Adres metni en fazla 60 karakter olmalıdır",

                    input3: "Lütfen şehrinizi giriniz!",
                    input3min: "Şehir en az 3 karakter olmalıdır!",
                    input3max: "Şehir en fazla 20 karakter olmalıdır",

                    input4: "Lütfen ülkenizi girin!",
                    input4min: "Ülke en az 3 karakter olmalıdır",
                    input4max: "Ülke en fazla 20 karakter olmalıdır",

                    input5: "Lütfen Posta kodunuzu giriniz!",
                    input5min: "Posta kodu en az 5 karakter olmalıdır!",
                    input5max: "Posta kodu en fazla 5 karakter olmalıdır !!",
                    input6: "",
                  },
                },
              },
              form4: {
                text1: "Kart Sahibi Tam Adı",
                text2: "Kart Numarası",
                text3: "Son Kullanım Yılı",
                text4: "Son Kullanım Ayı",
                text5: "CVC",
                btn: "Satın Al",
                validation: {
                  input1: "Kart sahibi adını giriniz!",
                  input1min: "Kart sahibi adı en az 2 karakter olmalıdır",

                  input2: "Kart numarasını giriniz!",

                  input3: "Son kullanma ayınızı giriniz!",
                  input3min: "Son kullanma ayı en az 2 karakter olmalıdır!",
                  input3max: "Son kullanma ayı en fazla 2 karakter olmalıdır",

                  input4: "Son kullanma yılınızı girin!",
                  input4min: "Son kullanma yılı en az 2 karakter olmalıdır",
                  input4max: "Son kullanma yılı en fazla 2 karakter olmalıdır",

                  input5: "CVC'nizi giriniz!",
                  input5min: "CVC en az 3 karakter olmalıdır!",
                  input5max: "CVC en fazla 3 karakter olmalıdır!",
                  giriş6: "",
                },
              },
              completed: {
                text1: "Alım tamamlandı!",
                text2:
                  "CereMeet'in premium özelliklerinden faydalanabiliriniz.",
              },
              card: {
                text1: "Sepetim",
                text2: "Toplam",
              },
              btn: "İlerle",
            },
            pagenotfound: {},
          },
          components: {
            forms: {
              login: {
                header: "Ceremeet'e Giriş Yap",
                inputEmail: "E-posta",
                inputPwd: "Şifre",
                placeholder1: "Şifreniz",
                text1: "Beni hatırla",
                text2: "Şifremi unuttum",
                text3: "Henüz hesabın yok mu?",
                text4: "Hesap oluştur!",
                button: {
                  btnSubmit: "Giriş yap",
                  btnGoogle: "Google ile giriş yap",
                },
                validation: {
                  input1: "Lütfen e-posta adresinizi giriniz!",
                  input1format: "E-posta formatı doğru değil!",
                  input2: "Lütfen şifrenizi girin!",
                  input2min: "Şifreniz en az 8 karakter olmalıdır",
                  input2max: "Şifreniz en fazla 25 karakter olmalıdır",
                  input3: "",
                },
              },
              register: {
                header: "Ceremeet'e Kayıt Ol",
                inputEmail: "E-posta",
                inputPwd: "Şifre",
                inputName: "Tam adınız",
                inputConPwd: "Şifrenizi Doğrulayın",
                placeholder1: "Şifreniz",
                placeholder2: "Tekrar Şifreniz",
                button: {
                  btnSubmit: "Kayıt ol",
                  btnGoogle: "Google ile kayıt ol",
                },
                validation: {
                  input1: "Lütfen adınızı giriniz!",
                  input2: "Lütfen e-posta adresinizi giriniz!",
                  input2format: "E-posta formatı doğru değil!",
                  input3: "Lütfen şifrenizi girin!",
                  input3min: "Şifreniz en az 8 karakter olmalıdır",
                  input3max: "Şifreniz en fazla 25 karakter olmalıdır",
                  input4: "Lütfen şifrenizi tekrar giriniz",
                  input5: "Şifreler uyuşmuyor!",
                  input: "",
                },
              },
              forgot: {
                header: "Doğrulama Kodu Al",
                inputEmail: "E-posta",
                placeholder1: "Şifreniz",
                button: {
                  btnSubmit: "Gönder",
                },
                inputPwd: "Şifreniz",
                inputConPwd: "Şifrenizi Doğrulayın",
                placeholder2: "Kod",
                header2: "Yeni Şifre",
                text1: "Doğrulama Kodu",
              },
              contact: {
                text1: "Adınız",
                text2: "E-posta Adresi",
                text3: "Mesajınız",
                text4: "Yeni ürünleriniz hakkıda bilgi almak istiyorum.",
                button: "Gönder",
                validation: {
                  input3: "Lütfen mesajınızı giriniz!",
                },
              },
            },
            secondHero: {
              header: {
                title:
                  "CereMeet metaverse'de bulunan bir üretim ve iletişim aracıdır.",
                description:
                  "CereMeet, geleneksel iletişim platformlarının yeteneklerini fotogerçekçi avatarlarla son derece gerçekçi bir 3B ortamda bir araya getirir.",
                button: {
                  btnSubmit: "Başlayın",
                },
              },
            },
            navbar: {
              main: {
                text1: "Fiyatlar",
                text2: "İletişim",
                text3: "S.S.S",
                text4: "Kılavuz",
                text5: "Cere",
                link: "https://pdate.ceremeet.com/ceremeetPictures/pdf/CereMeet_Kullanim_Kilavuzu.pdf",
              },
              right: {
                text1: "Giriş yap",
                text2: "Kayıt ol",
              },
              drawer: {
                text1: "Anasayfa",
                text2: "Fiyatlar",
                text3: "İletişim",
                text4: "S.S.S",
                text5: "Kılavuz",
                link: "https://pdate.ceremeet.com/ceremeetPictures/pdf/CereMeet_Kullanim_Kilavuzu.pdf",
              },
              modal1: {
                text1: "CereMeet üyelik seviyenizi azaltıyorsunuz.",
                text2: "Emin misiniz?",
                text3: "Üyelik İptali",
                btn1: "İptal Et",
                btn2: "Geri",
              },
              modal2: {
                text1: "CereMeet ürünlerini almak için",
                text2: "Giriş yapmanız gerekiyor.",
                text3: "Lütfen giriş yapın",
                btn1: "Giriş yap",
                btn2: "Geri",
              },
              profile: {
                text1: "Yükleniyor...",
                text2: "Profilinize erişmek için lütfen giriş yapın!",
                btn1: "Üyeliği yönet",
                btn2: "Şifre Yenile",
                btn3: "Çıkış yap",
                btn4: "İptal et",
                btn5: "Geri",
              },
              popover1: {
                text1: "Seçilmiş bir paket yok!",
                btn1: "Hemen Al",
              },
            },
            footer: {
              text1: "Gizlilik Politikası",
              text2: "Kullanım Koşulları",
            },
            errors: {
              title: "Hata",
              404: "Sayfa bulunamadı.",
              1001: "Bu id'ye sahip bir adres zaten mevcut",
              1002: "Bu adresi silme yetkiniz yok",
              1003: "Bu adresi görüntüleme yetkiniz yok",
              1004: "Bu isme sahip bir adres zaten var",
              1005: "Bu başlığa sahip bir adres zaten var",
              1101: "Doğrulama hatası",
              2001: "Geçersiz eposta veya şifre",
              2002: "Hesabınız doğrulanmamış, lütfen doğrulama e-postanızı kontrol edin",
              2003: "Hatalı eposta veya şifre",
              2004: "Geçersiz eposta veya şifre",
              2006: "Eposta doğrulanamadı",
              2007: "Erişim anahtarı yenilenemedi",
              2008: "Bu epostaya sahip bir kullanıcı mevcut",
              2009: "Eposta yenileme kodunuz geçerli değil",
              3001: "Eposta gönderirken bir sorunla karşılaşıldı, lütfen tekrar deneyin",
              4001: "Bu ID ile bir toplantı bulunamadı",
              5001: "Yapay zeka kotanızı doldurdunuz",
              5002: "Diyalog limitine ulaşıldı",
              5003: "Bu ID ile bir bot bulunamadı",
              5004: "Yapay zeka cevabı alınamadı",
              5005: "Soru kullanıcı politikamızı ihlal ediyor",
              6001: "Bu ID ile bir kullanıcıya rastlanmadı",
              6002: "Kullanıcı adı daha önce alınmış",
              7001: "Giriş yapılamadı",
              7002: "Geçersiz anahtar veya kullanıcı yok",
              7003: "Geçersiz anahtar veya oturum kapatılmış",
              7004: "Geçersiz anahtar veya oturum kapatılmış",
              9001: "Zaten bu üyeliğe sahipsiniz",
              9002: "Üyeliğiniz bulunmamakta",
              100001: "Sistem hatası",
              100310: "Geçersiz token isteği.",
              100311: "Access tokenın geçerlilik süresi doldu.",
              100312: "Kimlik doğrulama hatası!",
              100300: "Üye işyeri bilgisine erişim izniniz yok.",
              200301: "Email zorunlu alandır.",
              200302: "Email bilgisi en fazla {0} karakter olmalıdır.",
              200303: "Email formatı hatalı.",
              200304: "Kimlik numarası zorunlu alandır.",
              200305: "Kimlik numarası en fazla {0} karakter olmalıdır",
              200306: "Kimlik numarası en az {0} karakter olmalıdır.",
              200310: "Geçersiz telefon numarası.",
              200311: "Telefon numarası en fazla {0} karakter olmalıdır.",
              200320: "Geçersiz Sayfalama isteği.",
              200500: "Ürün adı zorunlu alandır.",
              200501: "En az bir parametrenin gönderilmesi zorunludur.",
              200600: "Ödeme planı zorunlu alandır.",
              200601: "Fiyat bilgisi zorunlu alandır.",
              200602: "Geçersiz fiyat bilgisi.",
              200603: "Ödeme periyodu zorunlu alandır.",
              200604: "Geçersiz ödeme periyodu.",
              200605: "Döviz tipi zorunlu alandır.",
              200606: "Ödeme tipi zorunlu alandır.",
              200607: "Geçersiz ödeme tipi.",
              200608: "Geçersiz deneme süresi.",
              200610: "Referans kodu zorunlu alandır.",
              200611: "Geçersiz ödeme aralığı.",
              200700: "Müşteri adı zorunlu alandır.",
              200701: "Müşteri soyadı zorunlu alandır.",
              200702: "Müşteri telefon numarası zorunlu alandır.",
              200703: "Fatura adresi zorunlu alandır.",
              200710: "En az bir parametrenin gönderilmesi zorunludur.",
              200711: "Referans kodu zorunlu alandır.",
              200800: "Adres zorunlu alandır.",
              200801: "Adres bilgisi en az {0} karakter olmalıdır.",
              200802: "İletişim adı zorunlu alandır.",
              200803: "İletişim adı en az {0} karakter olmalıdır.",
              200804: "Şehir zorunlu alandır.",
              200805: "Şehir en az {0} karakter olmalıdır.",
              200806: "Ülke zorunlu alandır.",
              200807: "Ülke en az {0} karakter olmalıdır.",
              200808: "Posta kodu en az {0} karakter olmalıdır.",
              200900: "Referans kodu zorunlu alandır.",
              200901: "Referans kodu zorunlu alandır.",
              200902: "Geçersiz abonelik durumu.",
              201550: "Fiyat bilgisi gönderilmesi zorunludur",
              201551: "Fiyat bilgisi sıfırdan büyük olmalıdır.",
              201000: "Ürün bilgisi bulunamadı.",
              201001: "Ürün zaten var.",
              201002: "Aktif ürün bulunamadı.",
              201500: "Ödeme planı bulunamadı.",
              201501: "Ödeme planı zaten var.",
              201052: "Ödeme planı aktif değil.",
              201053: "Ödeme planı silinmek için uygun değil.",
              201054: "Ödeme planı aktif değil.",
              201100: "Müşteri bulunamadı.",
              201101: "Müşteri zaten var.",
              201102: "Müşteri aktif değil.",
              201103: "Müşterinin kartı olmalı.",
              201400: "Abonelik bulunamadı.",
              201401: "Bu abonelik aktif edilemez.",
              201402: "Bu abonelik yükseltilemez.",
              201403: "Bu abonelik iptal edilemez.",
              201404: "Abonelik aynı ödeme planına sahip.",
              201405:
                "Abonelik başka bir ürüne ait ödeme planına yükseltilemez.",
              201406:
                "Abonelik farklı ödeme sıklığına sahip ödeme plana yükseltilemez.",
              201450: "Ödeme bulunamadı.",
              200750: "Kart verisi gönderilmesi zorunludur.",
              200751: "Kartın kaydedilmesine izin verilmesi zorunludur.",
              200903: "Abonelik referens kodu zorunlu alandır.",
              200905: "Abonelik durumu kart güncelleme için uygun değil.",
              201553: "Kart müşteriye ait değil.",
              201451: "Ödeme tekrar denemek için uygun değil.",
              201600: "Ödeme formu bulunamadı.",
              201601: "Ödeme formu tamamlanmamış.",
              201700: "Yinelenen ödeme girişimi bulunamadı!",
              201900: "Döviz cinsi bulunamadı.",
            },
            successes: {
              title: "İşlem Başarılı",
              payment: {
                text1: "Profil bilgileri güncellendi.",
                text2: "Adres bilgileri güncellendi.",
                text3: "Ödeme başarıyla yapıldı.",
              },
              login: {
                text1: "Giriş yaptınız.",
              },
              register: {
                text1: "Profil oluşturuldu.",
              },
              forgot: {
                text1: "Yenileme kodu eposta adresinize gönderildi.",
                text2: "Şifreniz yenilendi.",
              },
              contact: {
                text1: "Mesajınız gönderildi.",
              },
            },
          },
        },
      },
    },
  });

export default i18n;
