import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistor, store } from "./app/store";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider, createEmotionCache } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

const container = document.getElementById("root")!;
const root = createRoot(container);

const appendCache = createEmotionCache({ key: "mantine", prepend: false });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="331611527191-q8bq3m7qq8e39c9j0sgl100up58vu92h.apps.googleusercontent.com">
          <BrowserRouter>
            <MantineProvider
              emotionCache={appendCache}
              withGlobalStyles
              withNormalizeCSS
              theme={{
                colors: {
                  teal: [
                    "#d8ffff",
                    "#acffff",
                    "#7dffff",
                    "#4dffff",
                    "#28ffff",
                    "#18e5e6",
                    "#00b2b3",
                    "#007f80",
                    "#004d4e",
                    "#001b1d",
                  ],
                  somer: [
                    "#ffffff",
                    "#f2f2f2",
                    "#d9d9d9",
                    "#bfbfbf",
                    "#a6a6a6",
                    "#8c8c8c",
                    "#737373",
                    "#595959",
                    "#404040",
                    "#262626",
                  ],
                },
              }}
            >
              <NotificationsProvider
                zIndex={3000}
                position="top-right"
                autoClose={3000}
              >
                <App />
              </NotificationsProvider>
            </MantineProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
