import React from 'react'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube'

const NewGallerySection = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="text-white body-font z-50 w-full min-h-screen snap-center">
    <div className="container lg:px-20 px-5 py-24 mx-auto flex flex-wrap">
      <div className="flex w-full flex-wrap mb-12">
        <h1 className="sm:text-6xl text-[42px] font-extrabold title-font text-[#009999] lg:w-1/3 lg:mb-0 mb-4">{t("pages.home.gallery.header.title")}</h1>
        <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-lg self-center">{t("pages.home.gallery.header.description")}</p>
      </div>
      <div className="flex flex-wrap md:-m-2 -m-1">
        <div className="flex flex-wrap sm:w-1/2 bg-transparent">
          <div className="md:p-2 p-1 sm:w-1/2 bg-transparent">
            <img alt="gallery" className="w-full object-cover h-full object-center block hover:scale-150 transition-all hover:shadow-xl " src="https://pdate.ceremeet.com/ceremeetPictures/img/img.png"/>
          </div>
          <div className="md:p-2 p-1 sm:w-1/2 bg-transparent">
            <img alt="gallery" className="w-full object-cover h-full object-center block hover:scale-150 transition-all hover:shadow-xl " src="https://pdate.ceremeet.com/ceremeetPictures/img/img4.png"/>
          </div>
          <div className="flex flex-row md:p-2 p-1 w-full justify-center hover:scale-120 transition-all">
            <ReactPlayer width='100%'  url="https://www.youtube.com/watch?v=epuKItKPw5w"/>
          </div>
        </div>
        <div className="flex flex-wrap sm:w-1/2">
          <div className="flex flex-row md:p-2 p-1 my-2 w-full justify-center hover:scale-120 transition-all">
            <ReactPlayer width='100%' url="https://www.youtube.com/watch?v=bYX_oePqfNI"/>
          </div>
          <div className="md:p-2 p-1 w-full sm:w-1/2 bg-transparent">
            <img alt="gallery" className="w-full object-cover h-full object-center block hover:scale-150 transition-all hover:shadow-xl " src="https://pdate.ceremeet.com/ceremeetPictures/img/img5.jpg"/>
          </div>
          <div className="md:p-2 w-full sm:w-1/2 bg-transparent">
            <img alt="gallery" className="w-full object-cover h-full object-center block hover:scale-150 transition-all hover:shadow-xl " src="https://pdate.ceremeet.com/ceremeetPictures/img/img7.jpg"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default NewGallerySection