import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  createStyles,
} from "@mantine/core";
import { FormikHelpers, Formik, Form, Field, ErrorMessage } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/store";
import { useLoginUserMutation } from "../../../services/authApi";
import { loaderIsOn, loaderIsOff } from "../../../slices/modalSlice";
import { useGetUserInfoQuery } from "../../../services/userApi";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import loginWithGoogle from "../../../assets/loginWithGoogle.png";
import { setAuthCredentials } from "../../../slices/authSlice";
import { useGoogleLogin } from "@react-oauth/google";

const buttonStyle = {
  backgroundImage: `url(${loginWithGoogle})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  width: "200px",
  height: "150px",
  border: "none",
};
const containerStyle = {
  display: "flex",
  justifyContent: "center",
};
const useStyles = createStyles((theme) => ({
  label: {
    color: "white",
  },
  input: {
    color: "black",
    borderWidth: "2px",
    "&:focus": {
      borderColor: "#009999",
      color: "black",
    },

    "&:checked": {
      backgroundColor: "#009999",
    },
  },
  description: {
    color: "black",
  },

  button: {
    borderColor: "#009999",
    backgroundColor: "#009999",
    "&:hover": {
      boxShadow: "1px 1px 2px 2px #009999",
      backgroundColor: "#009999",
      border: "none",
    },
  },
}));

interface formValues extends Yup.InferType<typeof validationSchema> {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email address!"),
  password: Yup.string().min(8).max(20).required("Please enter your password!"),
});

export function NewLoginForm() {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();

  const [rememberMeIsSelected, setRememberMeIsSelected] =
    useState<boolean>(false);
  const [logUser, response] = useLoginUserMutation();
  // const [ getUserInfo , response2] = useGetUserInfoMutation()
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSuccess = async (codeResponse: any) => {
    console.log(codeResponse);
    await fetch(
      `https://api.ceremeet.com/api/auth/google/callback/code/${codeResponse.access_token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        dispatch(setAuthCredentials(data));
        navigateToHome();
      });
  };

  const onFailure = (res: any) => {
    console.log("Login failed. Error:", res.error);
  };

  const options = {
    onSuccess,
    onFailure,
    redirectUri: "https://api.ceremeet.com/api/auth/google/callback",
  };

  const login = useGoogleLogin(options);

  function navigateToResetPwd() {
    // event.preventDefault();
    console.log("To reset pwd");
    navigate("/forgotpassword", { replace: true });
  }
  function navigateToRegister() {
    // event.preventDefault();
    console.log("To register");
    navigate("/register", { replace: true });
  }

  function navigateToHome() {
    // event.preventDefault();
    console.log("To home");
    navigate("/", { replace: true });
  }

  const successNotification = (title: string, message: string) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.green[6],
          borderColor: theme.colors.green[6],
          "&::before": { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: theme.colors.green[7] },
        },
      }),
    });
  };

  //error notification
  const errorNotification = (title: string, message: any) => {
    showNotification({
      title: title,
      message: message,
      styles: (theme) => ({
        root: {
          backgroundColor: theme.colors.red[6],
          borderColor: theme.colors.red[6],
          "&::before": { backgroundColor: theme.white },
        },
        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          "&:hover": { backgroundColor: theme.colors.red[7] },
        },
      }),
    });
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("components.forms.login.validation.input1format"))
          .required(t("components.forms.login.validation.input1")),
        password: Yup.string()
          .min(8, t("components.forms.login.validation.input2min"))
          .max(25, t("components.forms.login.validation.input2max"))
          .required(t("components.forms.login.validation.input2")),
      })}
      onSubmit={async (
        values: formValues,
        { setSubmitting, resetForm }: FormikHelpers<formValues>
      ) => {
        //sends form values to the server then processes response data on handleSubmit function
        try {
          dispatch(loaderIsOn());
          const payload = await logUser(values).unwrap();
          // await getUserInfo()
          setTimeout(() => {
            successNotification(
              t(`components.successes.title`),
              t(`components.successes.login.text1`)
            );
            resetForm();
            dispatch(loaderIsOff());
            navigateToHome();
          }, 2500);
          console.log("fulfilled ", payload);
        } catch (error: any) {
          setTimeout(() => {
            dispatch(loaderIsOff());
          }, 2500);
          console.log("Hata Kodu" + t(`components.errors.title`));
          console.log(error.data.errorCode);
          errorNotification(
            t(`components.errors.title`),
            t(`components.errors.${error.data.errorCode.toString()}`)
          );
          console.error("rejected ", error);
        }
      }}
    >
      <Form style={{ zIndex: 1000 }}>
        <div className="-z-0  2xl:mr-38 sm:mr-8 lg:mr-16 md:mr-12">
          <Container
            size={420}
            px={12}
            pt={20}
            pb={25}
            my={0}
            sx={{
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              borderRadius: "25px",
              borderWidth: "0px",
              minWidth: "340px",
              borderColor: "white",
              minHeight: "auto",
            }}
          >
            <Title
              align="center"
              sx={(theme) => ({ fontWeight: 600, color: "white", px: 2 })}
            >
              {t("components.forms.login.header")}
            </Title>

            <Paper
              pt={20}
              pb={20}
              px={18}
              mt={30}
              sx={{ backgroundColor: "transparent", height: "auto" }}
            >
              <Field name="email">
                {({ field, form }: any) => (
                  <TextInput
                    {...field}
                    classNames={classes}
                    name="email"
                    type={"text"}
                    error={
                      form.touched.email && form.errors.email ? (
                        <ErrorMessage name={"email"} />
                      ) : null
                    }
                    label={t("components.forms.login.inputEmail")}
                    placeholder="mail@ceremeet.com"
                    required
                  />
                )}
              </Field>
              <Field classNames={classes} name="password">
                {({ field, form }: any) => (
                  <PasswordInput
                    {...field}
                    name="password"
                    classNames={classes}
                    error={
                      form.touched.password && form.errors.password ? (
                        <ErrorMessage name={"password"} />
                      ) : null
                    }
                    label={t("components.forms.login.inputPwd")}
                    placeholder={t("components.forms.login.placeholder1")}
                    required
                    mt="md"
                  />
                )}
              </Field>

              <Group position="apart" mt="lg">
                <Checkbox
                  label={t("components.forms.login.text1")}
                  sx={{ lineHeight: 1 }}
                  onChange={() => setRememberMeIsSelected((prev) => !prev)}
                  classNames={classes}
                />
                <Anchor<"a">
                  sx={{ color: "white" }}
                  italic
                  onClick={navigateToResetPwd}
                  size="sm"
                >
                  {t("components.forms.login.text2")}
                </Anchor>
              </Group>
              <Text sx={{ color: "white" }} size="sm" align="justify" mt={20}>
                {t("components.forms.login.text3")}{" "}
                <Anchor<"a">
                  sx={{ color: "white", alignSelf: "flex-end" }}
                  href="#"
                  size="sm"
                  ml={7}
                  onClick={navigateToRegister}
                >
                  {t("components.forms.login.text4")}
                </Anchor>
              </Text>
              <Button
                type="submit"
                className={classes.button}
                variant="filled"
                fullWidth
                mt="xl"
                mb={24}
              >
                {t("components.forms.login.button.btnSubmit")}
              </Button>
              <hr className="mb-6" />
              <Group position="apart">
                <button type="button" className="bg-white hover:bg-gray-100 transition-all w-full h-12 rounded-full flex flex-row items-center px-12 justify-evenly text-[15px] text-[#636769] font-[600] font-roboto" onClick={() => login()}>
                  <svg viewBox="0 0 16 16" className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path fill="#4285F4" d="M14.9 8.161c0-.476-.039-.954-.121-1.422h-6.64v2.695h3.802a3.24 3.24 0 01-1.407 2.127v1.75h2.269c1.332-1.22 2.097-3.02 2.097-5.15z"/><path fill="#34A853" d="M8.14 15c1.898 0 3.499-.62 4.665-1.69l-2.268-1.749c-.631.427-1.446.669-2.395.669-1.836 0-3.393-1.232-3.952-2.888H1.85v1.803A7.044 7.044 0 008.14 15z"/><path fill="#FBBC04" d="M4.187 9.342a4.17 4.17 0 010-2.68V4.859H1.849a6.97 6.97 0 000 6.286l2.338-1.803z"/><path fill="#EA4335" d="M8.14 3.77a3.837 3.837 0 012.7 1.05l2.01-1.999a6.786 6.786 0 00-4.71-1.82 7.042 7.042 0 00-6.29 3.858L4.186 6.66c.556-1.658 2.116-2.89 3.952-2.89z"/>
                  </svg>
                  {t("components.forms.login.button.btnGoogle")}
                </button>
              </Group>
            </Paper>
          </Container>
        </div>
      </Form>
    </Formik>
  );
}
